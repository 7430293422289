import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {   
        home:'Home',
        pricing:'Pricing',
        features:'Features',
        contact:'Contact',
        signIn:'Sign In',
        getRegister:'Get Registered',
        registerNow:'Register Now',
        contactUs:'Contact-us',
        admin:'Admin',
        candidate:'Candidates',
        assess:'Assessments',
        liveAssess:'Live Assessments',
        question:'Questions',
        dashboardChart1:'Payment(Date wise)',
        dashboardChart2:'Participation(Date wise)',
        dashboardChart3:'Participation(Topic wise)',
        dashboardChart4:'Login(Date wise)',
        language:'Language',
        personalize:'Personalize',
        leaveHeader:'Want to leave?',
        leaveConfirm:'Press Signout to leave',
        stay:'Stay Here',
        signOut:'Sign Out',
        Profile:'Profile',
        Password:'Password',
        Browsing:'Browsing',
        FAQ:'FAQ',
        email:'Email',
        telephone:'Telephone',
        message:'Message',
        submit:'Submit',
        password:'Password',
        name:'Name',
        forgetPassword:'Forget Password',
        register:'Register',
        close:'Close',
        sentPassword:'Sent Password',
        candidateRegister:'Candidate Registration',
        adminRegister:'Admin Registration',
        passwordLengthMessage:'At least 8 characters',
        required:'Required',
        min8Character:'Min 8 characters',
        emailInvalid:'E-mail must be valid',
        assessments:'assessments',
        questionsOnEachAssessment:'questions on each assessment',
        responsesForEachAssessment:'responses for each assessment',
        subscribe:'Subscribe',
        newMenu:'New Menu',
        editMenu:'Edit Menu',
        menuTitle:'Menu Title',
        url:'URL',
        orderNo:'Order No.',
        iconMaterial:'Icon(Material-Icon)',
        actions:'Actions',
        save:'Save',
        cancel:'Cancel',
        ok:'OK',
        deleteConfirmMessage:'Are you sure you want to delete this item?',
        mobile:'Mobile',
        dateOfBirth:'Date of Birth',
        displayName:'Display Name',
        role:'Role',
        plan:'Plan',
        interval:'Interval',
        paymentMode:'Payment Mode',
        expireyDate:'Expiry Date',
        transactionDetail:'Transaction Detail',
        newUser:'New User',
        editUser:'Edit User',
        billingPlan:'Billing Plan',
        profilePicture:'Profile Picture',
        renewPlan:'Renew Plan',
        previousPlan:'Previous Plan',
        previousPaymentMode:'Previous Payment Mode',
        previousTransactionDetail:'Previous Transaction Detail',
        renew:'Renew',
        newPlan:'New Plan',
        newMode:'New Mode',
        delete:'Delete',
        description:'Description',
        newRole:'New Role',
        editRole:'Edit Role',
        assignedMenus:'Assigned Menus',
        edit:'Edit',
        newFaq:'New Faq',
        editFaq:'Edit Faq',
        title:'Title',
        dateTime:'Date-Time',
        assessment:'Assessment',
        priceUsd:'Price(USD)',
        questionPerAssessmentCount:'Questions(Per Assessment)',
        responsePerAssessmentCount:'Response(Per Assessment)',
        startDate:'Date Start',
        endDate:'Date End',
        stripeSessionId:'Stripe Session ID',
        userEmail:'User Email',
        transactionEmail:'Transaction Email',
        editPlan:'Edit Plan',
        additionalText:'Additional Text',
        Monthly:'Monthly',
        yearly:'Yearly',
        generalSettings:'General Settings',
        examSettings:'Exam Settings',
        paymentSettings:'Payment Settings',
        emailSettings:'Email Settings',
        colorSettings:'Color Settings',
        landingPage:'Landing Page',
        emailText:'Email Text',
        siteTitle:'Site Title',
        welcomeMessage:'Welcome Message',
        copyrightText:'Copyright Text',
        allowWelcomeEmail:'Allow Welcome Email',
        allowFAQ:'Allow FAQ',
        siteLogo:'Site Logo',
        appVersion:'App Version',
        siteFavicon:'Site Favicon',
        browserrefreshendtheexam:'Browser refresh end the exam',
        sitelogoonexampage:'Site logo on exam page',
        stripe:'Stripe',
        currency:'Currency',
        currencySymbol:'Currency Symbol',
        stripeSecretkey:'Stripe Secret key',
        port:'Email Port',
        hostSmtp:'Host(SMTP)',
        appBar:'App Bar',
        footer:'Footer',
        body:'Body',
        homeHeaderTop:'Home Header Top',
        homeDetailTop:'Home Detail Top',
        homeHeaderBottom:'Home Header Bottom',
        homeDetailBottom:'Home Detail Bottom',
        homeBox1Header:'Home Box1 Header',
        homeBox1Detail:'Home Box1 Detail',
        homeBox2Header:'Home Box2 Header',
        homeBox2Detail:'Home Box2 Detail',
        homeBox3Header:'Home Box3 Header',
        homeBox3Detail:'Home Box3 Detail',
        homeBox4Header:'Home Box4 Header',
        homeBox4Detail:'Home Box4 Detail',
        feature1Header:'Feature1 Header',
        feature1Detail:'Feature1 Detail',
        feature2Header:'Feature2 Header',
        feature2Detail:'Feature2 Detail',
        feature3Header:'Feature3 Header',
        feature3Detail:'Feature3 Detail',
        feature4Header:'Feature4 Header',
        feature4Detail:'Feature4 Detail',
        registrationText:'Registration Text',
        conactusText:'Conact-us Text',
        conactusTelephone:'Conact-us Telephone',
        conactusEmail:'Conact-us Email',
        landingFooterText:'Landing Footer Text',
        footerFacebook:'Footer Facebook',
        footerTwitter:'Footer Twitter',
        footerLinkedIn:'Footer LinkedIn',
        footerInstagram:'Footer Instagram',
        subject:'Subject',
        header:'Header',
        forgetPasswordEmail:'Forget Password Email',
        welcomeEmail:'Welcome Email',
        invitationEmail:'Invitation Email',
        reportEmail:'Report Email',
        newPassword:'New Password',
        confirmNewPassword:'Confirm New Password',
        change:'Change',
        logInTime:'LogIn Time',
        logOutTime:'LogOut Time',
        ip:'IP',
        browser:'Browser',
        browserVersion:'Browser Version',
        platform:'Platform',
        id:'ID',
        questionCategoryName:'Question Category Name',
        newCategory:'New Category',
        editCategory:'Edit Category',
        internetDisruption:'Internet Disruption!',
        noInternetMsg:"It's looks like your internet connectivity is not stable or you are in offline.You can stay here, we will let you know when you back in online.",
        entryRestricted:'Entry Restricted',
        forbiddenMsg:'You have no access here.You are trying something which access has limited for you.',
        errorBackMsg:'Try going back to sign in page by clicking',
        here:'here',
        pagenotfound:'Page not found',
        notFoundMsg:'The page you are trying to get to never existed in this reality,or has migrated to a parallel universe.',
        tokenExpired:'Token Expired',
        unauthorizedMsg:'Your current token has expired! you need to sign in again.',
        numberofQuestions:'Number of Questions',
        timeMin:'Time(min)',    
        marks:'Marks',
        multipleInput:'Multiple Input',
        multipleAnswer:'Multiple Answer',
        multitleAttempt:'Multitle Attempt',
        scheduleStartDate:'Schedule Start Date',
        scheduleEndDate:'Schedule End Date',
        status:'Status',
        goLive:'Go Live',
        stopLive:'Stop Live',
        newAssessment:'New Assessment',
        editAssessment:'Edit Assessment',
        markOption:'Mark Option',
        allowMultipleAttempt:'Allow Unlimited attempt for a single user',
        allowMultipleAnswer:'Take multiple answer for single question by user',
        allowMultipleInput:'Multiple option set for single question',
        reqTimeInMin:'Required Time(In minutes)',
        passMarks:'Pass Marks',
        certificateTemplate:'Certificate Template',
        displayStopOption:'Display Stop Option',
        displaySkipOption:'Display Skip Option',
        questionSuffle:'Question Suffle',
        correctAnswerDisplay:'Correct answer display to candidates',
        participantOption:'Participation Option',
        sentInvitationtoEmail:'Sent Invitation to Email',
        participantsEmail:'Participants Email(Comma seperated)',
        sentLive:'Are you sure you want to send this Assessment live?',
        stopFromLive:'Are You sure to Stop this Assessment from live?',
        addQuestions:'Add Questions',
        addInstructions:'Add Instructions',
        newInstruction:'New Instruction',
        editInstruction:'Edit Instruction',
        instruction:'Instruction',
        newQuestion:'New Question',
        editQuestion:'Edit Question',
        sampleCSV:'Sample CSV',
        uploadInstructions:'Upload Instructions',
        questions:'Questions',
        category:'Category',
        correctAnswer:'Correct Answer',
        codeSnippet:'Code Snippet',
        questionType:'Question Type',
        complexityLavel:'Complexity Lavel',
        questionCategory:'Question Category',
        questionYoutubeVideo:'Question Video(youtube embed video)',
        questionImage:'Question Image',
        answerExplanation:'Answer Explanation',
        selectcategorytofilter:'Select category to filter',
        time:'Time',
        minutes:' minutes',
        view:'View',
        newTemplate:'New Template',
        editTemplate:'Edit Template',
        certificateTitle:'Certificate Title',
        certificateTopHeader:'Certificate Top Header',
        certificateMainText:'Certificate Main Text',
        topLeftLogo:'Top Left Logo',
        topRightLogo:'Top Right Logo',
        bottomLogo:'Bottom Logo',
        backGround:'BackGround',
        signatureLeft:'Signature Left',
        signatureLeftText:'Signature Left Text',
        signatureRight:'Signature Right',
        signatureRightText:'Signature Right Text',
        selectonetofilter:'Select one to filter',
        sentEmail:'Send Email',
        sentemailtocheckedstudents:'Send email to checked students',
        search:'Search',
        timeTakenMin:'Time taken(min)',
        marksObtained:'Marks Obtained',
        attemptNumber:'Attempt Number',
        attemptDateTime:'Attempt Date-Time',
        questionMark:'Question Mark',
        answerInput:'Answer Input',
        skipped:'Skipped?',
        yourMarks:'Your Marks',
        pendingExamine:'Pending Examine',
        resultSheet:'Result Sheet',
        certificate:'Certificate',
        amountPaidUSD:'Amount Paid(USD)',
        noAssessment:'No Assessment Available!',
        availableAssessment:'Available Assessments',
        instructions:'Instructions',
        continue:'Continue',
        Dashboard:'Dashboard',
        Menus:'Menus',
        Roles:'Roles',
        Users:'Users',
        Category:'Category',
        Assessments:'Assessments',
        Questions:'Questions',
        Reports:'Reports',
        CertificateTemplate:'Certificate Template',
        ExamineAndReports:'Exam & Reports',
        Analytics:'Analytics',
        Contacts:'Contacts',
        Payments:'Payments',
        Plans:'Plans',
        AppSettings:'App Settings',
        nameEmptyRuleLanding:'Name can not be empty',
        nameCharacterRuleLanding:'Length must be greater than or equal to 6 caracteres',
        emailEmptyRuleLanding:'Email can not be empty',
        emailWrongRuleLanding:'Wrong Email!',
        messageEmptyRuleLanding:'Message can not be empty',
        messageCharacterRuleLanding:'Length must be greater than or equal to 10 caracteres',
        successfullySubmitted:'Successfully Submitted',
        planExpiredMsg:'Your payment plan expired! Click renew to get that again.',
        billingPlanRenewed:'Billing plan renewed',
        planRenewalSuccess:'Billing plan renewal is successful ! Sign in again.',
        newContentsAvailable:'New contents available! Click refresh to affect changes.',
        mainText:'Main Text',
        menuDeleteRestriction:'This Menu is restricted to delete.',
        successfullyDeleted:'Successfully Deleted',
        successfullySaved:'Successfully Saved',
        duplicateMenuTitle:'Duplicate Menu Title!',
        duplicateURL:'Duplicate URL!',
        duplicateOrderNo:'Duplicate Order No.!',
        orderNomustgreaterthan0:'Order No. must greater than 0!',
        somethingUnexpected:'Something Unexpected!',
        successfullyUpdated:'Successfully Updated',
        successfullyAssigned:'Successfully Assigned',
        successfullyUnAssigned:'Successfully un-assigned',
        limitExpired:'You exceed your Billing plan limit.Not allwed to create a new one!',
        dateExpired:'You exceed your Billing plan Date.Not allwed to create a new one!',
        duplicateAssessmentTitle:'Duplicate Assessment Title!',
        backOnline:'Back Online!',
        backOnlineMsg:'Connection is back and you can start browsing again.',
        backgroundColor:'BackgroundColor',
        pleasecheckyourEmail:'Please check your Email',
        emailsentSuccessful:'Email sent Successful',
        notanimage:'Not an image',
        duplicateFaq:'Duplicate FAQ!',
        duplicateInstruction:'Duplicate Instruction!',
        successfullyRenewed:'Successfully renewed',
        notallowedtodeletethisondemoversion:'this operation not allowed on demo version!',
        thisplanhasexistingusersNotallowedtodeletethis:'This plan has existing users. Not allowed to delete this.',
        duplicateTitle:'Duplicate Title!',
        duplicatePrice:'Duplicate Price!',
        thereisnouserforthisemail:'There is no user for this email',
        pleasepayfirstthisisaninvalidsession:'Please pay first. This is an invalid session!',
        thisemailalreadyhaveauser:'This Email already have a user!',
        thisemailnotallowedtoregister:'This email not allowed to register. Please check!',
        rolerestricted:'This Role is restricted to delete.',
        duplicateRolename:'Duplicate Role name!',
        surveyReset:'As you Switched to Survey, Mark & Required time values are reset to initial state.',
        marksBasedReset:'As you switched to mark based Assessment,you need to set correct answer for all questions of this Assessment.',
        questionWiseReset:'As you switched to Question wise set Mark Option,you need to check correct answer & mark for all questions of this Assessment.',
        equalDistributionReset:'As you switched to Equal distribution Mark Option,you have to live this Assessment again.',
        marksChangedReset:'As you changed the Marks,you have to live this Assessment again.',
        liveInterruptionForNoQuestion:'Not possible to live this Assessment.Please add some questions!',
        liveInterruptionForNoCorrectAnswer:'Not possible to live this Assessment.Questions have no correct answer.Please set them first!',
        liveInterruptionForNoMarksSet:'Not possible to live this Assessment.Questions marks not set yet.Please set them first!',
        thissssessmentislivenow:'This Assessment is live now!',
        thisAssessmentisstoppedfromlive:'This Assessment is stopped from live!',
        deleteAssessmentReset:'As you deleted a question from a live Assessment, you have to start this Assessment again from Assessments List.',
        maxQuestionQuota:'You already reached maximun quota of question limit ! Not allwed to create a new one!',
        duplicateQuestion:'Duplicate Question!',
        newQuestionAddAssessmentStart:'As you added a new question to a live Assessment, you have to start this Assessment again from Assessments list',
        questionMarksChangedAssessmentStart:'This item is stopped from live as you changed marks of a running Assessment. you have to start this Assessment again from Assessments list',
        bulkQuestionAdd:'As you added bulk questions, you have to start this Assessment again from Assessments list if it was live',
        categoryUsageOthers:'This category is using with question. Not possible to delete.',
        categoryExists:'This Category already exists!',
        pdf:'PDF',
        excel:'EXCEL',
        csv:'CSV',
        typePassword:'Type password',
        wrongPassword:'Wrong password!',
        passwordNotMatched:'New and Confirm password not matched!',
        passwordChangedNotAllowedDemo:'Password change not allowed for this user on demo version.',
        onlinePaymentnotallowed:'Online Payment not allowed from here!',
        pleaseCheckyouremail:'Please Check your email',
        incorrectEmailPassword:'Incorrect Email/Password!',
        errorNetworkConnection:'Error established in network connection!',
        thisFieldMaynotbeEmpty:'This field may not be empty',
        theValueMustbeanIntegerNumber:'The value must be an integer number',
        theValueMustBeGreaterThanZero:'The value must be greater than zero',
        emailSettingsNotDone:'Email settings not done yet! Please ask your admin to do so.',
        noCheckedStudent:'No checked candidates found! First check candidates from list then sent email to them',
        emailSentSuccessfully:'Email sent successfully',
        markCanNotBeLessZero:'Marks Obtain can not be empty and less then 0!',
        marksObtaincannotgreaterthanQuestionMarks:'Mark Obtain can not greater than Question Mark!',
        containsDescriptiveQuestions:'This Assessment contains descriptive question. You will find this result on Reports after reviewing by Admin.',
        student:'Student',
        yourAnswer:'Your Answer',
        questionMarks:'Question Marks',
        thisAssessmentWilllive:'This assessment will live based on schedule.',
        paymentSettingsNotDone:'Your payment settings not done yet! Please set those then impose price here.',
        noScheduleStartDate:'No Schedule start date found! please put one.',
        scheduleStartDateShouldGreater:'Schedule end date should be greater than or equal to Schedule start date!',
        noCommaSeperatedEmail:'No comma seperated emails found! Add some comma seperated emails to Participants Email',
        passMarksshouldnotgreaterthanAssessmentMarks:'Pass Mark should not greater than Assessment Mark!',
        writeYourAnswerHere:'Write your answer here',   
        submitAnswer:'Submit Answer',     
        emptyAnswer:'Empty answer!',
        multipleAnswernotAllowed:'Multiple answer not allowed!',
        csvUploadInstruction:'Not uploaded! Please check your CSV carefully also follow upload instructions.',
        csvUploadError:'This is not an CSV file ! Only CSV file upload is allowed',
        emptyCorrectAnswer:'Correct answer empty!please check.',
        onlyYouTubeVideo:'Only youtube embed video is allowed!please check the Question video.',
        billingPlanRenewalSuccess:'Billing plan renewal is successful ! Sign in again.',
        csvUpload:'CSV Upload',
        skip:'Skip',
        start:'Start',
        refresh:'Refresh',
        pay:'Pay'      

    },
    'bn': {
        home:'হোম',
        pricing:'মূল্য নির্ধারণ',
        features:'বৈশিষ্ট্য',
        contact:'যোগাযোগ',
        signIn:'সাইন ইন',
        getRegister:'নিবন্ধিত হন',
        registerNow:'এখন নিবন্ধন করুন',
        contactUs:'যোগাযোগ করুন',
        admin:'অ্যাডমিন',
        candidate:'প্রার্থী',
        assess:'মূল্যায়ন',
        liveAssess:'লাইভ মূল্যায়ন',
        question:'প্রশ্ন',
        dashboardChart1:'পেমেন্ট(তারিখ অনুযায়ী)',
        dashboardChart2:'অংশগ্রহণ(তারিখ অনুসারে)',
        dashboardChart3:'অংশগ্রহণ(বিষয় অনুযায়ী)',
        dashboardChart4:'লগইন(তারিখ অনুসারে)',
        language:'ভাষা',
        personalize:'ব্যক্তিগতকৃত',
        leaveHeader:'চলে যেতে চাই?',
        leaveConfirm:'ছেড়ে যেতে সাইনআউট টিপুন',
        stay:'এখানে থাকুন',
        signOut:'সাইন আউট',
        Profile:'প্রোফাইল',
        Password:'পাসওয়ার্ড',
        Browsing:'ব্রাউজিং',
        FAQ:'এফএকিউ',
        email:'ইমেইল',
        telephone:'টেলিফোন',
        message:'বার্তা',
        submit:'জমা দিন',
        password:'পাসওয়ার্ড',
        name:'নাম',
        forgetPassword:'পাসওয়ার্ড ভুলে গেছেন',
        register:'নিবন্ধন',
        close:'বন্ধ',
        sentPassword:'পাসওয়ার্ড পাঠান',
        candidateRegister:'প্রার্থী নিবন্ধন',
        adminRegister:'অ্যাডমিন নিবন্ধন',
        passwordLengthMessage:'কমপক্ষে 8টি অক্ষর',
        required:'প্রয়োজন',
        min8Character:'কমপক্ষে 8টি অক্ষর',
        emailInvalid:'ইমেল বৈধ হতে হবে',
        assessments:'মূল্যায়ন',
        questionsOnEachAssessment:'প্রতিটি মূল্যায়নের প্রশ্ন',
        responsesForEachAssessment:'প্রতিটি মূল্যায়নের জন্য প্রতিক্রিয়া',
        subscribe:'সাবস্ক্রাইব',
        newMenu:'নতুন মেনু',
        editMenu:'মেনু পরিবর্তন',
        menuTitle:'মেনু শিরোনাম',
        url:'লিংক',
        orderNo:'ক্রমিক নং',
        iconMaterial:'আইকন(গুগল ম্যাটেরিয়াল আইকন)',
        actions:'কর্ম',
        save:'সংরক্ষণ',
        cancel:'বাতিল',
        ok:'ওকে',
        deleteConfirmMessage:'আপনি এই আইটেমটি মুছে ফেলতে চান?',
        mobile:'মুঠোফোন',
        dateOfBirth:'জন্ম তারিখ',
        displayName:'প্রদর্শনের নাম',
        role:'ভূমিকা',
        plan:'পরিকল্পনা',
        interval:'অন্তর',
        paymentMode:'পরিশোধের মাধ্যম',
        expireyDate:'মেয়াদ শেষ হওয়ার তারিখ',
        transactionDetail:'লেনদেনের বিবরণ',
        newUser:'নতুন ব্যবহারকারী',
        editUser:'ব্যবহারকারী সম্পাদনা করুন',
        billingPlan:'বিলিং পরিকল্পনা',
        profilePicture:'প্রোফাইল ছবি',
        renewPlan:'প্ল্যান রিনিউ',
        previousPlan:'পূর্ববর্তী পরিকল্পনা',
        previousPaymentMode:'আগের পেমেন্ট মোড',
        previousTransactionDetail:'পূর্ববর্তী লেনদেনের বিবরণ',
        renew:'পুনর্নবীকরণ',
        newPlan:'নতুন পরিকল্পনা',
        newMode:'নতুন পেমেন্ট মোড',
        delete:'মুছে ফেলুন',
        description:'বর্ণনা',
        newRole:'নতুন ভূমিকা',
        editRole:'ভূমিকা সম্পাদনা করুন',
        assignedMenus:'নির্ধারিত মেনু',
        edit:'সম্পাদনা করুন',
        newFaq:'নতুন এফএকু',
        editFaq:'এফএকু সম্পাদনা করুন',
        title:'শিরোনাম',
        dateTime:'তারিখ-সময়',
        assessment:'মূল্যায়ন',
        priceUsd:'মূল্য(USD)',
        questionPerAssessmentCount:'প্রশ্ন (প্রতি মূল্যায়ন)',
        responsePerAssessmentCount:'প্রতিক্রিয়া (প্রতি মূল্যায়ন)',
        startDate:'তারিখ শুরু',
        endDate:'তারিখ শেষ',
        stripeSessionId:'স্ট্রাইপ সেশন আইডি',
        userEmail:'ব্যবহারকারীর ইমেইল',
        transactionEmail:'লেনদেন ইমেল',
        editPlan:'পরিকল্পনা সম্পাদনা করুন',
        additionalText:'অতিরিক্ত টেক্সট',
        Monthly:'মাসিক',
        yearly:'বার্ষিক',
        generalSettings:'সাধারণ সেটিংস',
        examSettings:'পরীক্ষার সেটিংস',
        paymentSettings:'পেমেন্ট সেটিংস',
        emailSettings:'ইমেল সেটিংস',
        colorSettings:'রঙের সেটিংস',
        landingPage:'অবতরণ পাতা',
        emailText:'ইমেল পাঠ্য',
        siteTitle:'সাইট শিরোনাম',
        welcomeMessage:'স্বাগত বার্তা',
        copyrightText:'কপিরাইট টেক্সট',
        allowWelcomeEmail:'স্বাগতম ইমেল অনুমতি দিন',
        allowFAQ:'FAQ অনুমতি দিন',
        siteLogo:'সাইটের লোগো',
        appVersion:'অ্যাপ সংস্করণ',
        siteFavicon:'সাইট ফেভিকন',
        browserrefreshendtheexam:'ব্রাউজার রিফ্রেশ পরীক্ষা শেষ',
        sitelogoonexampage:'পরীক্ষার পৃষ্ঠায় সাইটের লোগো',
        stripe:'স্ট্রাইপ',
        currency:'মুদ্রা',
        currencySymbol:'মুদ্রার প্রতীক',
        stripeSecretkey:'স্ট্রাইপ সিক্রেট কী',
        port:'ইমেল পোর্ট',
        hostSmtp:'হোস্ট(SMTP)',
        appBar:'অ্যাপ বার',
        footer:'ফুটার',
        body:'শরীর',
        homeHeaderTop:'হোম হেডার শীর্ষ',
        homeDetailTop:'হোম বিস্তারিত শীর্ষ',
        homeHeaderBottom:'হোম হেডার নীচে',
        homeDetailBottom:'হোম বিস্তারিত নীচে',
        homeBox1Header:'হোম বক্স 1 হেডার',
        homeBox1Detail:'হোম বক্স 1 বিস্তারিত',
        homeBox2Header:'হোম বক্স 2 হেডার',
        homeBox2Detail:'হোম বক্স 2 বিস্তারিত',
        homeBox3Header:'হোম বক্স 3 হেডার',
        homeBox3Detail:'হোম বক্স 3 বিস্তারিত',
        homeBox4Header:'হোম বক্স 4 হেডার',
        homeBox4Detail:'হোম বক্স 4 বিস্তারিত',
        feature1Header:'বৈশিষ্ট্য1 হেডার',
        feature1Detail:'বৈশিষ্ট্য1 বিস্তারিত',
        feature2Header:'বৈশিষ্ট্য2 হেডার',
        feature2Detail:'বৈশিষ্ট্য2 বিস্তারিত',
        feature3Header:'বৈশিষ্ট্য3 হেডার',
        feature3Detail:'বৈশিষ্ট্য3 বিস্তারিত',
        feature4Header:'বৈশিষ্ট্য4 হেডার',
        feature4Detail:'বৈশিষ্ট্য4 বিস্তারিত',
        registrationText:'নিবন্ধন পাঠ্য',
        conactusText:'আমাদের সাথে যোগাযোগ করুন পাঠ্য',
        conactusTelephone:'আমাদের সাথে যোগাযোগ করুন টেলিফোন',
        conactusEmail:'আমাদের সাথে যোগাযোগ করুন ইমেইল',
        landingFooterText:'ল্যান্ডিং ফুটার টেক্সট',
        footerFacebook:'ফুটার ফেসবুক',
        footerTwitter:'ফুটার টুইটার',
        footerLinkedIn:'ফুটার লিঙ্কডইন',
        footerInstagram:'ফুটার ইনস্টাগ্রাম',
        subject:'বিষয়',
        header:'হেডার',
        forgetPasswordEmail:'পাসওয়ার্ড ইমেল',
        welcomeEmail:'স্বাগতম ইমেইল',
        invitationEmail:'আমন্ত্রণ ইমেইল',
        reportEmail:'রিপোর্ট ইমেল',
        newPassword:'নতুন পাসওয়ার্ড',
        confirmNewPassword:'নতুন পাসওয়ার্ড নিশ্চিত',
        change:'পরিবর্তন',
        logInTime:'লগইন সময়',
        logOutTime:'লগআউট সময়',
        ip:'আইপি',
        browser:'ব্রাউজার',
        browserVersion:'ব্রাউজার সংস্করণ',
        platform:'প্ল্যাটফর্ম',
        id:'আইডি',
        questionCategoryName:'প্রশ্ন বিভাগের নাম',
        newCategory:'নতুন বিভাগ',
        editCategory:'বিভাগ সম্পাদনা করুন',
        internetDisruption:'ইন্টারনেট ব্যাহত!',
        noInternetMsg:'দেখে মনে হচ্ছে আপনার ইন্টারনেট সংযোগ স্থিতিশীল নয় বা আপনি অফলাইনে আছেন৷ আপনি এখানে থাকতে পারেন, আপনি অনলাইনে ফিরে আসলে আমরা আপনাকে জানাব৷',
        entryRestricted:'প্রবেশ সীমাবদ্ধ',
        forbiddenMsg:'এখানে আপনার কোনো অ্যাক্সেস নেই৷ আপনি এমন কিছু চেষ্টা করছেন যা আপনার জন্য সীমাবদ্ধ',
        errorBackMsg:'ক্লিক করে সাইন ইন পৃষ্ঠায় ফিরে যাওয়ার চেষ্টা করুন৷',
        here:'এখানে',
        pagenotfound:'পৃষ্ঠা খুঁজে পাওয়া যায়নি',
        notFoundMsg:'আপনি যে পৃষ্ঠাটি পেতে চেষ্টা করছেন তা এই বাস্তবতায় কখনও বিদ্যমান ছিল না, বা একটি সমান্তরাল মহাবিশ্বে স্থানান্তরিত হয়েছে।',
        tokenExpired:'টোকেনের মেয়াদ শেষ',
        unauthorizedMsg:'আপনার বর্তমান টোকেনের মেয়াদ শেষ হয়ে গেছে! আপনাকে আবার সাইন ইন করতে হবে।',
        numberofQuestions:'প্রশ্নের সংখ্যা',
        timeMin:'সময় (মিনিট)',    
        marks:'মার্ক',
        multipleInput:'একাধিক ইনপুট',
        multipleAnswer:'একাধিক উত্তর',
        multitleAttempt:'একাধিক প্রচেষ্টা',
        scheduleStartDate:'শুরুর তারিখ নির্ধারণ করুন',
        scheduleEndDate:'শেষের তারিখ নির্ধারণ করুন',
        status:'স্ট্যাটাস',
        goLive:'লাইভে যাও',
        stopLive:'লাইভ থামাও',
        newAssessment:'নতুন মূল্যায়ন',
        editAssessment:'মূল্যায়ন সম্পাদনা করুন',
        markOption:'মার্ক অপশন',
        allowMultipleAttempt:'একক ব্যবহারকারীর জন্য সীমাহীন প্রচেষ্টার অনুমতি দিন',
        allowMultipleAnswer:'ব্যবহারকারী দ্বারা একক প্রশ্নের জন্য একাধিক উত্তর নিন',
        allowMultipleInput:'একক প্রশ্নের জন্য একাধিক বিকল্প উত্তর',
        reqTimeInMin:'প্রয়োজনীয় সময় (মিনিট)',
        passMarks:'পাশের নম্বর',
        certificateTemplate:'সার্টিফিকেট টেমপ্লেট',
        displayStopOption:'ডিসপ্লে স্টপ অপশন',
        displaySkipOption:'স্কিপ অপশন প্রদর্শন করুন',
        questionSuffle:'প্রশ্ন এলোমেলো',
        correctAnswerDisplay:'প্রার্থীদের সঠিক উত্তর প্রদর্শন',
        participantOption:'অংশগ্রহণের উপায়',
        sentInvitationtoEmail:'ইমেল আমন্ত্রণ পাঠানো হয়েছে',
        participantsEmail:'অংশগ্রহণকারীদের ইমেল (কমা আলাদা করা)',
        sentLive:'আপনি কি নিশ্চিত আপনি এই মূল্যায়ন লাইভ পাঠাতে চান?',
        stopFromLive:'আপনি কি লাইভ থেকে এই মূল্যায়ন বন্ধ করতে নিশ্চিত?',
        addQuestions:'প্রশ্ন যোগ করুন',
        addInstructions:'নির্দেশাবলী যোগ করুন',
        newInstruction:'নতুন নির্দেশ',
        editInstruction:'নির্দেশনা সম্পাদনা করুন',
        instruction:'নির্দেশনা',
        newQuestion:'নতুন প্রশ্ন',
        editQuestion:'প্রশ্ন সম্পাদনা করুন',
        sampleCSV:'নমুনা CSV',
        uploadInstructions:'আপলোড নির্দেশাবলী',
        questions:'প্রশ্ন',
        category:'শ্রেণী',
        correctAnswer:'সঠিক উত্তর',
        codeSnippet:'প্রোগ্রামিং কোড',
        questionType:'প্রশ্নের ধরন',
        complexityLavel:'জটিলতা লেভেল',
        questionCategory:'প্রশ্ন বিভাগ',
        questionYoutubeVideo:'প্রশ্ন ভিডিও (ইউটিউব এম্বেড ভিডিও)',
        questionImage:'প্রশ্ন ইমেজ',
        answerExplanation:'উত্তর ব্যাখ্যা',
        selectcategorytofilter:'ফিল্টার করার জন্য বিভাগ নির্বাচন করুন',
        time:'সময়',
        minutes:' মিনিট',
        view:'দেখ',
        newTemplate:'নতুন টেমপ্লেট',
        editTemplate:'টেমপ্লেট সম্পাদনা করুন',
        certificateTitle:'সার্টিফিকেট শিরোনাম',
        certificateTopHeader:' শীর্ষ শিরোনাম',
        certificateMainText:'সার্টিফিকেট মূল পাঠ্য',
        topLeftLogo:'উপরের বাম লোগো',
        topRightLogo:'উপরের ডানদিকে লোগো',
        bottomLogo:'নিচের লোগো',
        backGround:'ব্যাকগ্রাউন্ড',
        signatureLeft:'স্বাক্ষর বাম',
        signatureLeftText:'স্বাক্ষর বাম টেক্সট',
        signatureRight:'স্বাক্ষর ডান',
        signatureRightText:'স্বাক্ষর ডান টেক্সট',
        selectonetofilter:'ফিল্টার করার জন্য একটি নির্বাচন করুন',
        sentEmail:'ইমেইল পাঠান',
        sentemailtocheckedstudents:'চেক করা ছাত্রদের ইমেল পাঠান',
        search:'অনুসন্ধান করুন',
        timeTakenMin:'সময় লেগেছে(মিনিট)',
        marksObtained:'নম্বর পেয়েছেন',
        attemptNumber:'প্রচেষ্টা নম্বর',
        attemptDateTime:'তারিখ-সময় প্রচেষ্টা',
        questionMark:'প্রশ্নের নম্বর',
        answerInput:'উত্তর ইনপুট',
        skipped:'এড়িয়ে গেছে?',
        yourMarks:'তোমার মার্কস',
        pendingExamine:'নম্বর দেয়া বাকি আছে',
        resultSheet:'রেজাল্ট শিট',
        certificate:'সনদ',
        amountPaidUSD:'প্রদত্ত পরিমাণ (USD)',
        noAssessment:'কোন মূল্যায়ন নেই!',
        availableAssessment:'উপলব্ধ মূল্যায়ন',
        instructions:'নির্দেশনা',
        continue:'চালিয়ে যান',
        Dashboard:'ড্যাশবোর্ড',
        Menus:'মেনু',
        Roles:'ভূমিকা',
        Users:'ব্যবহারকারী',
        Category:'শ্রেণী',
        Assessments:'মূল্যায়ন',
        Questions:'প্রশ্ন',
        Reports:'রিপোর্ট',
        CertificateTemplate:'সার্টিফিকেট টেমপ্লেট',
        ExamineAndReports:'পরীক্ষা এবং রিপোর্ট',
        Analytics:'বিশ্লেষণ',
        Contacts:'পরিচিতি',
        Payments:'পেমেন্ট',
        Plans:'পরিকল্পনা সমূহ',
        AppSettings:'অ্যাপ সেটিংস',     
        nameEmptyRuleLanding:'নাম খালি রাখা যাবে না',
        nameCharacterRuleLanding:'দৈর্ঘ্য অবশ্যই 6 ক্যারাক্টারের বেশি বা সমান হতে হবে',
        emailEmptyRuleLanding:'ইমেল খালি হতে পারে না',
        emailWrongRuleLanding:'ভুল ইমেইল!',
        messageEmptyRuleLanding:'বার্তা খালি করা যাবে না',
        messageCharacterRuleLanding:'দৈর্ঘ্য অবশ্যই 10 ক্যারেক্টরের বেশি বা সমান হতে হবে',
        successfullySubmitted:'সফলভাবে জমা দেওয়া হয়েছে',
        planExpiredMsg:'আপনার পেমেন্ট প্ল্যানের মেয়াদ শেষ! এটি আবার পেতে পুনর্নবীকরণ ক্লিক করুন.',
        billingPlanRenewed:'বিলিং পরিকল্পনা পুনর্নবীকরণ করা হয়েছে',
        planRenewalSuccess:'বিলিং পরিকল্পনা পুনর্নবীকরণ সফল! আবার সাইন ইন করুন.',
        newContentsAvailable:'নতুন বিষয়বস্তু উপলব্ধ! পরিবর্তন প্রভাবিত করতে রিফ্রেশ ক্লিক করুন.',
        mainText:'মূল পাঠ্য',
        menuDeleteRestriction:'এই মেনু মুছে ফেলার জন্য সীমাবদ্ধ.',
        successfullyDeleted:'সফলভাবে মুছে ফেলা হয়েছে',
        successfullySaved:'সফলভাবে সংরক্ষিত',
        duplicateMenuTitle:'ডুপ্লিকেট মেনু শিরোনাম!',
        duplicateURL:'ডুপ্লিকেট ইউআরএল!',
        duplicateOrderNo:'ডুপ্লিকেট অর্ডার নং!',
        orderNomustgreaterthan0:'অর্ডার নম্বর অবশ্যই 0 এর বেশি হবে!',
        somethingUnexpected:'অপ্রত্যাশিত কিছু!',
        successfullyUpdated:'সফলভাবে আপডেট',
        successfullyAssigned:'সফলভাবে বরাদ্দ করা হয়েছে৷',
        successfullyUnAssigned:'সফলভাবে আন-অ্যাসাইন করা হয়েছে৷',
        limitExpired:'আপনি আপনার বিলিং পরিকল্পনার সীমা অতিক্রম করেছেন৷ একটি নতুন তৈরি করার অনুমতি নেই!',
        dateExpired:'আপনি আপনার বিলিং পরিকল্পনার তারিখ অতিক্রম করেছেন৷ একটি নতুন তৈরি করার অনুমতি নেই!',
        duplicateAssessmentTitle:'ডুপ্লিকেট মূল্যায়ন শিরোনাম!',
        backOnline:'ফিরে অনলাইন!',
        backOnlineMsg:'সংযোগ ফিরে এসেছে এবং আপনি আবার ব্রাউজিং শুরু করতে পারেন৷',
        backgroundColor:'পেছনের রঙ',
        pleasecheckyourEmail:'অনুগ্রহপূর্বক আপনার ইমেইল চেক করুন',
        emailsentSuccessful:'ইমেল পাঠানো সফল হয়েছে',
        notanimage:'ছবি নয়',
        duplicateFaq:'ডুপ্লিকেট FAQ!',
        duplicateInstruction:'অনুলিপি নির্দেশ!',
        successfullyRenewed:'সফলভাবে পুনর্নবীকরণ করা হয়েছে৷',
        notallowedtodeletethisondemoversion:'ডেমো সংস্করণে এই অপারেশন অনুমোদিত নয়!',
        thisplanhasexistingusersNotallowedtodeletethis:'এই প্ল্যানে বিদ্যমান ব্যবহারকারী রয়েছে। এটি মুছে ফেলার অনুমতি নেই.',
        duplicateTitle:'ডুপ্লিকেট শিরোনাম!',
        duplicatePrice:'ডুপ্লিকেট দাম!',
        thereisnouserforthisemail:'এই ইমেলের জন্য কোন ব্যবহারকারী নেই',
        pleasepayfirstthisisaninvalidsession:'আগে পেমেন্ট করুন. এটি একটি অবৈধ অধিবেশন!',
        thisemailalreadyhaveauser:'এই ইমেল ইতিমধ্যে একজন ব্যবহারকারী আছে!',
        thisemailnotallowedtoregister:'এই ইমেল নিবন্ধন করার অনুমতি নেই. চেক করুন!',
        rolerestricted:'এই ভূমিকা মুছে ফেলার জন্য সীমাবদ্ধ.',
        duplicateRolename:'সদৃশ ভূমিকার নাম!',
        limitExpiredContactAdmin:'সীমা ছাড়িয়ে গেছে! আপনার অ্যাডমিনের সাথে যোগাযোগ করুন.',
        cycleExpiredContactAdmin:'বিলিং চক্রের মেয়াদ শেষ! আপনার অ্যাডমিনের সাথে যোগাযোগ করুন.',
        surveyReset:'আপনি সমীক্ষায় স্যুইচ করার সাথে সাথে, মার্কস এবং প্রয়োজনীয় সময়ের মানগুলি প্রাথমিক অবস্থায় পুনরায় সেট করা হয়েছে।',
        marksBasedReset:'যেহেতু আপনি মার্ক ভিত্তিক মূল্যায়নে স্যুইচ করেছেন, আপনাকে এই মূল্যায়নের সমস্ত প্রশ্নের সঠিক উত্তর সেট করতে হবে।',
        questionWiseReset:'যেহেতু আপনি প্রশ্ন অনুসারে সেট মার্ক অপশনে স্যুইচ করেছেন, আপনাকে এই মূল্যায়নের সমস্ত প্রশ্নের জন্য সঠিক উত্তর এবং চিহ্নগুলি পরীক্ষা করতে হবে।',
        equalDistributionReset:'যেহেতু আপনি সমান বন্টন মার্ক বিকল্পে স্যুইচ করেছেন, আপনাকে আবার এই মূল্যায়ন করতে হবে।',
        marksChangedReset:'আপনি যেমন মার্কস পরিবর্তন করেছেন, আপনাকে আবার এই মূল্যায়ন করতে হবে।',
        liveInterruptionForNoQuestion:'এই মূল্যায়ন লাইভ করা সম্ভব নয়. কিছু প্রশ্ন যোগ করুন!',
        liveInterruptionForNoCorrectAnswer:'এই মূল্যায়ন লাইভ করা সম্ভব নয়। প্রশ্নের কোন সঠিক উত্তর নেই। অনুগ্রহ করে সেগুলি প্রথমে সেট করুন!',
        liveInterruptionForNoMarksSet:'এই মূল্যায়ন লাইভ করা সম্ভব নয়। প্রশ্ন চিহ্ন এখনও সেট করা হয়নি। অনুগ্রহ করে প্রথমে সেগুলি সেট করুন!',
        thissssessmentislivenow:'এই মূল্যায়ন এখন লাইভ!',
        thisAssessmentisstoppedfromlive:'এই মূল্যায়ন লাইভ থেকে বন্ধ!',
        deleteAssessmentReset:'আপনি একটি লাইভ মূল্যায়ন থেকে একটি প্রশ্ন মুছে ফেলার সাথে সাথে আপনাকে মূল্যায়ন তালিকা থেকে আবার এই মূল্যায়ন শুরু করতে হবে।',
        maxQuestionQuota:'আপনি ইতিমধ্যেই প্রশ্ন সীমার সর্বোচ্চ কোটায় পৌঁছেছেন! একটি নতুন তৈরি করার অনুমতি নেই!',
        duplicateQuestion:'ডুপ্লিকেট প্রশ্ন!',
        newQuestionAddAssessmentStart:'যেহেতু আপনি একটি লাইভ মূল্যায়নে একটি নতুন প্রশ্ন যোগ করেছেন, আপনাকে মূল্যায়ন তালিকা থেকে আবার এই মূল্যায়ন শুরু করতে হবে',
        questionMarksChangedAssessmentStart:'আপনি চলমান মূল্যায়নের চিহ্ন পরিবর্তন করার কারণে এই আইটেমটি লাইভ থেকে বন্ধ করা হয়েছে। আপনাকে মূল্যায়ন তালিকা থেকে আবার এই মূল্যায়ন শুরু করতে হবে',
        bulkQuestionAdd:'যেহেতু আপনি বাল্ক প্রশ্ন যোগ করেছেন, আপনাকে মূল্যায়ন তালিকা থেকে আবার শুরু করতে হবে যদি এটি লাইভ হয়',
        categoryUsageOthers:'এই বিভাগটি প্রশ্ন সহ ব্যবহার করছে। মুছে ফেলা সম্ভব নয়।',
        categoryExists:'এই বিভাগটি ইতিমধ্যেই বিদ্যমান!',
        pdf:'পিডিএফ',
        excel:'এক্সেল',
        csv:'সিএসভ',
        typePassword:'টাইপ পাসওয়ার্ড',
        wrongPassword:'ভুল গুপ্তশব্দ!',
        passwordNotMatched:'নতুন এবং নিশ্চিত পাসওয়ার্ড মেলেনি!',
        passwordChangedNotAllowedDemo:'ডেমো সংস্করণে এই ব্যবহারকারীর জন্য পাসওয়ার্ড পরিবর্তন অনুমোদিত নয়৷',
        onlinePaymentnotallowed:'এখান থেকে অনলাইন পেমেন্ট অনুমোদিত নয়!',
        pleaseCheckyouremail:'অনুগ্রহপূর্বক আপনার ইমেইল চেক করুন',
        incorrectEmailPassword:'ভুল ইমেইল/পাসওয়ার্ড!',
        errorNetworkConnection:'নেটওয়ার্ক সংযোগে ত্রুটি প্রতিষ্ঠিত হয়েছে!',
        thisFieldMaynotbeEmpty:'এই ক্ষেত্রটি খালি নাও থাকতে পারে',
        theValueMustbeanIntegerNumber:'মান একটি পূর্ণসংখ্যা সংখ্যা হতে হবে',
        theValueMustBeGreaterThanZero:'মান অবশ্যই শূন্যের বেশি হতে হবে',
        emailSettingsNotDone:'ইমেল সেটিংস এখনও করা হয়নি! অনুগ্রহ করে আপনার অ্যাডমিনকে তা করতে বলুন।',
        noCheckedStudent:'কোন চেক প্রার্থী পাওয়া গেছে! প্রথমে তালিকা থেকে প্রার্থীদের চেক করুন তারপর তাদের ইমেল পাঠান',
        emailSentSuccessfully:'ইমেল সফলভাবে পাঠানো হয়েছে',
        markCanNotBeLessZero:'মার্কস পাওয়া যাবে না খালি এবং 0 এর কম!',
        marksObtaincannotgreaterthanQuestionMarks:'প্রশ্ন নম্বরের চেয়ে বেশি মার্কস পাওয়া যাবে না!',
        containsDescriptiveQuestions:'এই মূল্যায়নে বর্ণনামূলক প্রশ্ন রয়েছে। অ্যাডমিন দ্বারা পর্যালোচনা করার পরে আপনি প্রতিবেদনে এই ফলাফলটি পাবেন।',
        student:'ছাত্র',
        yourAnswer:'তোমার উত্তর',
        questionMarks:'প্রশ্ন বধক',
        thisAssessmentWilllive:'এই মূল্যায়ন সময়সূচীর উপর ভিত্তি করে লাইভ হবে.',
        paymentSettingsNotDone:'আপনার পেমেন্ট সেটিংস এখনও সম্পন্ন হয়নি! অনুগ্রহ করে সেগুলি সেট করুন তারপর এখানে মূল্য আরোপ করুন।',
        noScheduleStartDate:'কোন সময়সূচী শুরুর তারিখ পাওয়া যায়নি! একটি রাখুন.',
        scheduleStartDateShouldGreater:'সময়সূচির শেষ তারিখ শিডিউল শুরুর তারিখের চেয়ে বড় বা সমান হওয়া উচিত!',
        noCommaSeperatedEmail:'কোন কমা পৃথক ইমেল পাওয়া যায়নি! অংশগ্রহণকারীদের ইমেলে কিছু কমা পৃথক ইমেল যোগ করুন',
        passMarksshouldnotgreaterthanAssessmentMarks:'পাস মার্ক অ্যাসেসমেন্ট মার্কের চেয়ে বেশি হওয়া উচিত নয়!',
        writeYourAnswerHere:'এখানে আপনার উত্তর লিখুন',   
        submitAnswer:'উত্তর দাখিল করো',     
        emptyAnswer:'খালি উত্তর!',
        multipleAnswernotAllowed:'একাধিক উত্তর অনুমোদিত নয়!',
        csvUploadInstruction:'আপলোড হয়নি! অনুগ্রহ করে আপনার CSV সাবধানে চেক করুন এছাড়াও আপলোড নির্দেশাবলী অনুসরণ করুন।',
        csvUploadError:'এটি একটি CSV ফাইল নয়! শুধুমাত্র CSV ফাইল আপলোড অনুমোদিত',
        emptyCorrectAnswer:'সঠিক উত্তর খালি! অনুগ্রহ করে চেক করুন।',
        onlyYouTubeVideo:'শুধুমাত্র ইউটিউব এম্বেড ভিডিও অনুমোদিত! অনুগ্রহ করে প্রশ্ন ভিডিওটি দেখুন।',
        billingPlanRenewalSuccess:'বিলিং পরিকল্পনা পুনর্নবীকরণ সফল! আবার সাইন ইন করুন.',
        csvUpload:'CSV আপলোড',
        skip:'এড়িয়ে যান',
        start:'শুরু',
        refresh:'রিফ্রেশ',
        pay:'পেমেন্ট'

    },
    'es': {   
        home:'Casa',
        pricing:'Precios',
        features:'Características',
        contact:'Contacto',
        signIn:'Registrarse',
        getRegister:'Regístrese',
        registerNow:'Regístrate ahora',
        contactUs:'Contáctenos',
        admin:'Administrador',
        candidate:'Candidatos',
        assess:'Evaluaciones',
        liveAssess:'Evaluaciones en vivo',
        question:'Preguntas',
        dashboardChart1:'Pago (fecha sabia)',
        dashboardChart2:'Participación (fecha sabia)',
        dashboardChart3:'Participación (tema sabio)',
        dashboardChart4:'Iniciar sesión (fecha sabia)',
        language:'Idioma',
        personalize:'Personalizar',
        leaveHeader:'¿Querer irse?',
        leaveConfirm:'Pulse Cerrar sesión para salir',
        stay:'Quédate aquí',
        signOut:'Desconectar',
        Profile:'Perfil',
        Password:'Clave',
        Browsing:'Hojeada',
        FAQ:'Preguntas más frecuentes',
        email:'Correo electrónico',
        telephone:'Teléfono',
        message:'Mensaje',
        submit:'Enviar',
        password:'Clave',
        name:'Nombre',
        forgetPassword:'Contraseña olvidada',
        register:'Registro',
        close:'Cerrar',
        sentPassword:'Contraseña enviada',
        candidateRegister:'Registro de candidatos',
        adminRegister:'Registro de administrador',
        passwordLengthMessage:'Al menos 8 carácteres',
        required:'Requerido',
        min8Character:'Mínimo 8 caracteres',
        emailInvalid:'El email debe ser válido',
        assessments:'evaluaciones',
        questionsOnEachAssessment:'preguntas en cada evaluacion',
        responsesForEachAssessment:'respuestas para cada evaluación',
        subscribe:'Suscribir',
        newMenu:'Nuevo Menú',
        editMenu:'Menú de edición',
        menuTitle:'Título del menú',
        url:'URL',
        orderNo:'N º de pedido.',
        iconMaterial:'Icono(Material-Icono)',
        actions:'Comportamiento',
        save:'Ahorrar',
        cancel:'Cancelar',
        ok:'DE ACUERDO',
        deleteConfirmMessage:'¿Estás seguro de que quieres eliminar este artículo?',
        mobile:'Móvil',
        dateOfBirth:'Fecha de cumpleaños',
        displayName:'Nombre para mostrar',
        role:'Role',
        plan:'Plan',
        interval:'Intervalo',
        paymentMode:'Modo de pago',
        expireyDate:'Fecha de caducidad',
        transactionDetail:'Detalle de la transacción',
        newUser:'Nuevo usuario',
        editUser:'editar usuario',
        billingPlan:'Plan de facturación',
        profilePicture:'Foto de perfil',
        renewPlan:'Plan Renovar',
        previousPlan:'Plano anterior',
        previousPaymentMode:'Modo de pago anterior',
        previousTransactionDetail:'Detalle de transacción anterior',
        renew:'Renovar',
        newPlan:'Nuevo plan',
        newMode:'Nuevo modo',
        delete:'Borrar',
        description:'Descripción',
        newRole:'Nuevo rol',
        editRole:'Editar rol',
        assignedMenus:'Menús asignados',
        edit:'Editar',
        newFaq:'Nuevas preguntas frecuentes',
        editFaq:'Editar preguntas frecuentes',
        title:'Título',
        dateTime:'Fecha y hora',
        assessment:'Evaluación',
        priceUsd:'Precio (dólares americanos)',
        questionPerAssessmentCount:'Preguntas (por evaluación)',
        responsePerAssessmentCount:'Respuesta (por evaluación)',
        startDate:'Fecha de inicio',
        endDate:'Fecha de finalización',
        stripeSessionId:'ID de sesión de banda',
        userEmail:'Correo electrónico del usuario',
        transactionEmail:'Correo electrónico de transacción',
        editPlan:'Editar plan',
        additionalText:'Texto adicional',
        Monthly:'Mensual',
        yearly:'Anual',
        generalSettings:'Configuración general',
        examSettings:'Configuración del examen',
        paymentSettings:'Configuración de pago',
        emailSettings:'Ajustes del correo electrónico',
        colorSettings:'Ajustes de color',
        landingPage:'Página de destino',
        emailText:'Texto de correo electrónico',
        siteTitle:'Título del sitio',
        welcomeMessage:'Mensaje de bienvenida',
        copyrightText:'Texto de derechos de autor',
        allowWelcomeEmail:'Permitir correo electrónico de bienvenida',
        allowFAQ:'Permitir preguntas frecuentes',
        siteLogo:'Logotipo del sitio',
        appVersion:'Version de aplicacion',
        siteFavicon:'Favicon del sitio',
        browserrefreshendtheexam:'Actualización del navegador finalizar el examen',
        sitelogoonexampage:'Logotipo del sitio en la página del examen',
        stripe:'Stripe',
        currency:'Divisa',
        currencySymbol:'Símbolo de moneda',
        stripeSecretkey:'Stripe Secret key',
        port:'Puerto de correo electrónico',
        hostSmtp:'Anfitrión (SMTP)',
        appBar:'Barra de aplicaciones',
        footer:'Pie de página',
        body:'Cuerpo',
        homeHeaderTop:'Parte superior del encabezado de inicio',
        homeDetailTop:'Inicio detalle superior',
        homeHeaderBottom:'Parte inferior del encabezado de inicio',
        homeDetailBottom:'Inicio detalle inferior',
        homeBox1Header:'Inicio Box1 Encabezado',
        homeBox1Detail:'Inicio Box1 Detalle',
        homeBox2Header:'Inicio Box2 Encabezado',
        homeBox2Detail:'Inicio Box2 Detalle',
        homeBox3Header:'Inicio Box3 Encabezado',
        homeBox3Detail:'Inicio Box3 Detalle',
        homeBox4Header:'Inicio Box4 Encabezado',
        homeBox4Detail:'Inicio Box4 Detalle',
        feature1Header:'Encabezado Característica1',
        feature1Detail:'Característica1 Detalle',
        feature2Header:'Encabezado Feature2',
        feature2Detail:'Característica2 Detalle',
        feature3Header:'Encabezado Feature3',
        feature3Detail:'Característica3 Detalle',
        feature4Header:'Encabezado Feature4',
        feature4Detail:'Característica4 Detalle',
        registrationText:'Texto de registro',
        conactusText:'Texto de contacto',
        conactusTelephone:'Contáctanos Teléfono',
        conactusEmail:'Correo electrónico de contacto',
        landingFooterText:'Texto del pie de página de aterrizaje',
        footerFacebook:'Pie de página de Facebook',
        footerTwitter:'Twitter de pie de página',
        footerLinkedIn:'Pie de página de LinkedIn',
        footerInstagram:'Pie de página de Instagram',
        subject:'Sujeto',
        header:'Encabezamiento',
        forgetPasswordEmail:'Olvidé mi contraseña Correo electrónico',
        welcomeEmail:'Correo electrónico de bienvenida',
        invitationEmail:'Correo electrónico de invitación',
        reportEmail:'Informe de correo electrónico',
        newPassword:'Nueva contraseña',
        confirmNewPassword:'Confirmar nueva contraseña',
        change:'Cambiar',
        logInTime:'Hora de inicio de sesión',
        logOutTime:'Hora de cierre de sesión',
        ip:'IP',
        browser:'Navegador',
        browserVersion:'Versión del navegador',
        platform:'Plataforma',
        id:'IDENTIFICACIÓN',
        questionCategoryName:'Pregunta Categoría Nombre',
        newCategory:'Nueva categoría',
        editCategory:'Editar categoria',
        internetDisruption:'Interrupción de Internet!',
        noInternetMsg:"Parece que su conexión a Internet no es estable o está desconectado. Puede quedarse aquí, le avisaremos cuando vuelva a conectarse.",
        entryRestricted:'Entrada restringida',
        forbiddenMsg:'No tienes acceso aquí. Estás intentando algo cuyo acceso está limitado para ti.',
        errorBackMsg:'Intente volver a la página de inicio de sesión haciendo clic en',
        here:'aquí',
        pagenotfound:'Página no encontrada',
        notFoundMsg:'La página a la que intenta acceder nunca existió en esta realidad o ha migrado a un universo paralelo.',
        tokenExpired:'Ficha caducada',
        unauthorizedMsg:'¡Tu token actual ha caducado! necesitas iniciar sesión de nuevo.',
        numberofQuestions:'numero de preguntas',
        timeMin:'Tiempo (min)',    
        marks:'Marcas',
        multipleInput:'Entrada múltiple',
        multipleAnswer:'Respuesta múltiple',
        multitleAttempt:'Intento múltiple',
        scheduleStartDate:'Programar fecha de inicio',
        scheduleEndDate:'Programar fecha de finalización',
        status:'Estado',
        goLive:'Ir a vivir',
        stopLive:'Detener en vivo',
        newAssessment:'Nueva evaluación',
        editAssessment:'Editar evaluación',
        markOption:'Marcar opción',
        allowMultipleAttempt:'Permitir intento ilimitado para un solo usuario',
        allowMultipleAnswer:'Tomar respuesta múltiple para una sola pregunta por usuario',
        allowMultipleInput:'Conjunto de opciones múltiples para una sola pregunta',
        reqTimeInMin:'Tiempo requerido (en minutos)',
        passMarks:'Aprobado',
        certificateTemplate:'Plantilla de certificado',
        displayStopOption:'Mostrar opción de parada',
        displaySkipOption:'Mostrar opción de omisión',
        questionSuffle:'Mezcla de preguntas',
        correctAnswerDisplay:'Mostrar la respuesta correcta a los candidatos',
        participantOption:'Opción de participación',
        sentInvitationtoEmail:'Invitación enviada al correo electrónico',
        participantsEmail:'Correo electrónico de los participantes (coma separada)',
        sentLive:'¿Está seguro de que desea enviar esta evaluación en vivo?',
        stopFromLive:'¿Está seguro de detener esta evaluación en vivo?',
        addQuestions:'Agregar preguntas',
        addInstructions:'Agregar instrucciones',
        newInstruction:'nueva instrucción',
        editInstruction:'Editar instrucción',
        instruction:'Instrucción',
        newQuestion:'nueva pregunta',
        editQuestion:'Editar pregunta',
        sampleCSV:'CSV de muestra',
        uploadInstructions:'Instrucciones de carga',
        questions:'Preguntas',
        category:'Categoría',
        correctAnswer:'Respuesta correcta',
        codeSnippet:'Fragmento de código',
        questionType:'tipo de pregunta',
        complexityLavel:'Nivel de complejidad',
        questionCategory:'Categoría de pregunta',
        questionYoutubeVideo:'Video de pregunta (video incrustado de youtube)',
        questionImage:'Imagen de pregunta',
        answerExplanation:'Respuesta Explicación',
        selectcategorytofilter:'Seleccionar categoría para filtrar',
        time:'Hora',
        minutes:' minutos',
        view:'Vista',
        newTemplate:'Nueva plantilla',
        editTemplate:'Editar plantilla',
        certificateTitle:'Título del certificado',
        certificateTopHeader:'Encabezado superior del certificado',
        certificateMainText:'Texto principal del certificado',
        topLeftLogo:'Logotipo superior izquierdo',
        topRightLogo:'Logotipo superior derecho',
        bottomLogo:'Logotipo inferior',
        backGround:'Antecedentes',
        signatureLeft:'Firma a la izquierda',
        signatureLeftText:'Firma Texto Izquierdo',
        signatureRight:'Derecho de firma',
        signatureRightText:'Firma Derecho Texto',
        selectonetofilter:'Seleccione uno para filtrar',
        sentEmail:'Enviar correo electrónico',
        sentemailtocheckedstudents:'Enviar correo electrónico a los estudiantes revisados',
        search:'Búsqueda',
        timeTakenMin:'Tiempo empleado (min)',
        marksObtained:'marcas obtenidas',
        attemptNumber:'Número de intento',
        attemptDateTime:'Intento Fecha-Hora',
        questionMark:'Signo de interrogación',
        answerInput:'Entrada de respuesta',
        skipped:'¿Omitido?',
        yourMarks:'tus marcas',
        pendingExamine:'Examen pendiente',
        resultSheet:'Hoja de resultados',
        certificate:'Certificado',
        amountPaidUSD:'Cantidad pagada (USD)',
        noAssessment:'¡No hay evaluación disponible!',
        availableAssessment:'Evaluaciones disponibles',
        instructions:'Evaluaciones disponibles',
        continue:'Continuar',
        Dashboard:'Tablero',
        Menus:'Menús',
        Roles:'Roles',
        Users:'Usuarios',
        Category:'Categoría',
        Assessments:'Evaluaciones',
        Questions:'Preguntas',
        Reports:'Informes',
        CertificateTemplate:'Plantilla de certificado',
        ExamineAndReports:'Examen e Informes',
        Analytics:'Analítica',
        Contacts:'Contactos',
        Payments:'Pagos',
        Plans:'planes',
        AppSettings:'Ajustes de Aplicacion',
        nameEmptyRuleLanding:'El nombre no puede estar vacío',
        nameCharacterRuleLanding:'La longitud debe ser mayor o igual a 6 caracteres',
        emailEmptyRuleLanding:'El correo electrónico no puede estar vacío',
        emailWrongRuleLanding:'¡Email incorrecto!',
        messageEmptyRuleLanding:'El mensaje no puede estar vacío',
        messageCharacterRuleLanding:'La longitud debe ser mayor o igual a 10 caracteres',
        successfullySubmitted:'Enviado correctamente',
        planExpiredMsg:'¡Tu plan de pago expiró! Haga clic en renovar para obtener eso nuevamente.',
        billingPlanRenewed:'Plan de facturación renovado',
        planRenewalSuccess:'¡La renovación del plan de facturación se realizó correctamente! Inicie sesión de nuevo.',
        newContentsAvailable:'¡Nuevos contenidos disponibles! Haga clic en actualizar para afectar los cambios.',
        mainText:'Texto principal',
        menuDeleteRestriction:'Este Menú está restringido para borrar.',
        successfullyDeleted:'Eliminado exitosamente',
        successfullySaved:'Guardado con éxito',
        duplicateMenuTitle:'¡Título del menú duplicado!',
        duplicateURL:'¡URL duplicada!',
        duplicateOrderNo:'¡Núm. de pedido duplicado!',
        orderNomustgreaterthan0:'¡El número de pedido debe ser mayor que 0!',
        somethingUnexpected:'¡Algo inesperado!',
        successfullyUpdated:'Actualizado exitosamente',
        successfullyAssigned:'Asignado con éxito',
        successfullyUnAssigned:'Exitosamente no asignado',
        limitExpired:'Excede el límite de su plan de facturación. ¡No está permitido crear uno nuevo!',
        dateExpired:'Excede la fecha de su plan de facturación. ¡No se permite crear uno nuevo!',
        duplicateAssessmentTitle:'¡Título de evaluación duplicado!',
        backOnline:'¡De nuevo en línea!',
        backOnlineMsg:'La conexión ha vuelto y puedes empezar a navegar de nuevo.',
        backgroundColor:'Color de fondo',
        pleasecheckyourEmail:'Por favor revise su correo electrónico',
        emailsentSuccessful:'Correo electrónico enviado con éxito',
        notanimage:'no es una imagen',
        duplicateFaq:'¡Preguntas frecuentes duplicadas!',
        duplicateInstruction:'¡Instrucción duplicada!',
        successfullyRenewed:'Renovado con éxito',
        notallowedtodeletethisondemoversion:'¡esta operación no está permitida en la versión de demostración!',
        thisplanhasexistingusersNotallowedtodeletethis:'Este plan tiene usuarios existentes. No se permite eliminar esto.',
        duplicateTitle:'¡Título duplicado!',
        duplicatePrice:'¡Precio duplicado!',
        thereisnouserforthisemail:'No hay usuario para este correo electrónico',
        pleasepayfirstthisisaninvalidsession:'Por favor, pague primero. ¡Esta es una sesión inválida!',
        thisemailalreadyhaveauser:'Este correo electrónico ya tiene un usuario!',
        thisemailnotallowedtoregister:'Este correo electrónico no permite registrarse. ¡Por favor, compruebe!',
        rolerestricted:'Esta función está restringida para eliminar.',
        duplicateRolename:'¡Nombre de rol duplicado!',
        surveyReset:'Al cambiar a Levantamiento, los valores de tiempo marcados y requeridos se restablecen al estado inicial.',
        marksBasedReset:'Como cambió a la evaluación basada en calificaciones, debe establecer la respuesta correcta para todas las preguntas de esta evaluación.',
        questionWiseReset:'A medida que cambió a la opción de marca de conjunto inteligente de preguntas, debe verificar la respuesta correcta y marcar todas las preguntas de esta evaluación.',
        equalDistributionReset:'Como cambió a la opción de marca de distribución equitativa, debe volver a vivir esta evaluación.',
        marksChangedReset:'Como cambiaste las Marcas, tienes que volver a vivir esta Evaluación.',
        liveInterruptionForNoQuestion:'No es posible vivir esta evaluación. ¡Por favor agregue algunas preguntas!',
        liveInterruptionForNoCorrectAnswer:'No es posible vivir esta evaluación. Las preguntas no tienen una respuesta correcta. ¡Por favor, configúrelas primero!',
        liveInterruptionForNoMarksSet:'No es posible vivir esta evaluación. Los signos de interrogación aún no se han establecido. ¡Por favor, póngalos primero!',
        thissssessmentislivenow:'¡Esta evaluación ya está disponible!',
        thisAssessmentisstoppedfromlive:'¡Esta evaluación se detuvo en vivo!',
        deleteAssessmentReset:'Como eliminó una pregunta de una evaluación en vivo, debe comenzar esta evaluación nuevamente desde la Lista de evaluaciones.',
        maxQuestionQuota:'¡Ya alcanzaste la cuota máxima de límite de preguntas! ¡No se permite crear uno nuevo!',
        duplicateQuestion:'¡Pregunta duplicada!',
        newQuestionAddAssessmentStart:'A medida que agregó una nueva pregunta a una evaluación en vivo, debe comenzar esta evaluación nuevamente desde la lista de evaluaciones',
        questionMarksChangedAssessmentStart:'Este elemento deja de estar activo cuando cambió las marcas de una evaluación en ejecución. debe comenzar esta evaluación nuevamente desde la lista de evaluaciones',
        bulkQuestionAdd:'A medida que agregó preguntas masivas, debe comenzar esta evaluación nuevamente desde la lista de evaluaciones si estaba activa',
        categoryUsageOthers:'Esta categoría se está utilizando con la pregunta. No es posible eliminar.',
        categoryExists:'¡Esta categoría ya existe!',
        pdf:'PDF',
        excel:'SOBRESALIR',
        csv:'CSV',
        typePassword:'Escriba contraseña',
        wrongPassword:'¡Contraseña incorrecta!',
        passwordNotMatched:'¡Nueva y Confirmar contraseña no coincide!',
        passwordChangedNotAllowedDemo:'El cambio de contraseña no está permitido para este usuario en la versión de demostración.',
        onlinePaymentnotallowed:'¡Pago en línea no permitido desde aquí!',
        pleaseCheckyouremail:'Por favor revise su correo electrónico',
        incorrectEmailPassword:'¡Correo electrónico/contraseña incorrectos!',
        errorNetworkConnection:'¡Error establecido en la conexión de red!',
        thisFieldMaynotbeEmpty:'Este campo no puede estar vacío',
        theValueMustbeanIntegerNumber:'El valor debe ser un número entero',
        theValueMustBeGreaterThanZero:'El valor debe ser mayor que cero.',
        emailSettingsNotDone:'¡La configuración de correo electrónico aún no se ha realizado! Pida a su administrador que lo haga.',
        noCheckedStudent:'¡No se encontraron candidatos marcados! Primero verifique los candidatos de la lista y luego envíeles un correo electrónico',
        emailSentSuccessfully:'Correo electrónico enviado con éxito',
        markCanNotBeLessZero:'Marcas Obtener no puede estar vacío y menos de 0!',
        marksObtaincannotgreaterthanQuestionMarks:'Mark Obtener no puede ser mayor que el signo de interrogación!',
        containsDescriptiveQuestions:'Esta evaluación contiene una pregunta descriptiva. Encontrará este resultado en los informes después de revisarlo el administrador.',
        student:'Alumno',
        yourAnswer:'Tu respuesta',
        questionMarks:'Signo de interrogación',
        thisAssessmentWilllive:'Esta evaluación vivirá según el cronograma.',
        paymentSettingsNotDone:'¡Tu configuración de pago aún no está lista! Establézcalos y luego imponga el precio aquí.',
        noScheduleStartDate:'¡No se encontró la fecha de inicio del programa! por favor pon uno.',
        scheduleStartDateShouldGreater:'¡La fecha de finalización del programa debe ser mayor o igual que la fecha de inicio del programa!',
        noCommaSeperatedEmail:'¡No se encontraron correos electrónicos separados por comas! Agregue algunos correos electrónicos separados por comas al correo electrónico de los participantes',
        passMarksshouldnotgreaterthanAssessmentMarks:'¡La marca de aprobación no debe ser mayor que la marca de evaluación!',
        writeYourAnswerHere:'Escribe tu respuesta aquí',   
        submitAnswer:'Enviar respuesta',     
        emptyAnswer:'¡Respuesta vacía!',
        multipleAnswernotAllowed:'¡Respuesta múltiple no permitida!',
        csvUploadInstruction:'¡No subido! Verifique su CSV cuidadosamente y también siga las instrucciones de carga.',
        csvUploadError:'¡Este no es un archivo CSV! Solo se permite la carga de archivos CSV',
        emptyCorrectAnswer:'¡La respuesta correcta está vacía! Verifique.',
        onlyYouTubeVideo:'¡Solo se permite el video incrustado de YouTube! Consulte el video de la pregunta.',
        billingPlanRenewalSuccess:'¡La renovación del plan de facturación se realizó correctamente! Inicie sesión de nuevo.',
        csvUpload:'Subir CSV',
        skip:'Saltar',
        start:'Comenzar',
        refresh:'Actualizar',
        pay:'Pago'
    },
    'it': {   
        home:'Casa',
        pricing:'Prezzi',
        features:'Caratteristiche',
        contact:'Contatto',
        signIn:'Registrazione',
        getRegister:'Registrati',
        registerNow:'Iscriviti ora',
        contactUs:'Contattaci',
        admin:'Amministratore',
        candidate:'Candidati',
        assess:'Valutazioni',
        liveAssess:'Valutazioni dal vivo',
        question:'Domande',
        dashboardChart1:'Pagamento (data saggia)',
        dashboardChart2:'Partecipazione (data saggia)',
        dashboardChart3:'Partecipazione (argomento saggio)',
        dashboardChart4:'Accesso (data saggia)',
        language:'Lingua',
        personalize:'Personalizzare',
        leaveHeader:'Vuoi andartene?',
        leaveConfirm:'Premi Esci per uscire',
        stay:'Rimani qui',
        signOut:'Disconnessione',
        Profile:'Profilo',
        Password:'Parola dordine',
        Browsing:'Navigazione',
        FAQ:'Domande frequenti',
        email:'E-mail',
        telephone:'Telefono',
        message:'Messaggio',
        submit:'Invia',
        password:"parola d'ordine",
        name:'Nome',
        forgetPassword:'Dimenticare la password',
        register:'Registrati',
        close:'Chiudere',
        sentPassword:'Password inviata',
        candidateRegister:'Registrazione del candidato',
        adminRegister:'Registrazione amministratore',
        passwordLengthMessage:'Almeno 8 caratteri',
        required:'Necessario',
        min8Character:'Minimo 8 caratteri',
        emailInvalid:"L'email deve essere valida",
        assessments:'valutazioni',
        questionsOnEachAssessment:'domande su ogni valutazione',
        responsesForEachAssessment:'risposte per ogni valutazione',
        subscribe:'sottoscrivi',
        newMenu:'Nuovo Menù',
        editMenu:'Menù Modifica',
        menuTitle:'Titolo del menù',
        url:'URL',
        orderNo:"Numero d'ordine.",
        iconMaterial:'Icona (Icona materiale)',
        actions:'Azioni',
        save:'Salva',
        cancel:'Annulla',
        ok:'OK',
        deleteConfirmMessage:'Sei sicuro di voler eliminare questo articolo?',
        mobile:'Mobile',
        dateOfBirth:'Data di nascita',
        displayName:'Nome da visualizzare',
        role:'Ruolo',
        plan:'Piano',
        interval:'Intervallo',
        paymentMode:'Metodo di pagamento',
        expireyDate:'Data di scadenza',
        transactionDetail:'Dettaglio transazione',
        newUser:'Nuovo utente',
        editUser:'Modifica utente',
        billingPlan:'Piano di fatturazione',
        profilePicture:'Immagine del profilo',
        renewPlan:'Rinnova piano',
        previousPlan:'Piano precedente',
        previousPaymentMode:'Modalità di pagamento precedente',
        previousTransactionDetail:'Dettaglio della transazione precedente',
        renew:'Rinnovare',
        newPlan:'Nuovo Piano',
        newMode:'Nuova modalità',
        delete:'Elimina',
        description:'Descrizione',
        newRole:'Nuovo ruolo',
        editRole:'Modifica ruolo',
        assignedMenus:'Menu assegnati',
        edit:'Modificare',
        newFaq:'Nuove domande frequenti',
        editFaq:'Modifica domande frequenti',
        title:'Titolo',
        dateTime:'Appuntamento',
        assessment:'Valutazione',
        priceUsd:'Prezzo (USD)',
        questionPerAssessmentCount:'Domande (per valutazione)',
        responsePerAssessmentCount:'Risposta (per valutazione)',
        startDate:'Data Inizio',
        endDate:'Data Fine',
        stripeSessionId:'Stripe Session ID',
        userEmail:"E-mail dell'utente",
        transactionEmail:'E-mail di transazione',
        editPlan:'Modifica piano',
        additionalText:'Testo aggiuntivo',
        Monthly:'Mensile',
        yearly:'Annuale',
        generalSettings:'impostazioni generali',
        examSettings:"Impostazioni dell'esame",
        paymentSettings:'Impostazioni di pagamento',
        emailSettings:"impostazioni dell 'email",
        colorSettings:'Impostazioni colore',
        landingPage:'Pagina di destinazione',
        emailText:'Testo e-mail',
        siteTitle:'Titolo del sito',
        welcomeMessage:'Messaggio di benvenuto',
        copyrightText:"Testo del diritto d'autore",
        allowWelcomeEmail:'Consenti e-mail di benvenuto',
        allowFAQ:'Consenti domande frequenti',
        siteLogo:'Logo del sito',
        appVersion:"Versione dell'app",
        siteFavicon:'Favicon del sito',
        browserrefreshendtheexam:"L'aggiornamento del browser termina l'esame",
        sitelogoonexampage:"Logo del sito sulla pagina dell'esame",
        stripe:'Stripe',
        currency:'Moneta',
        currencySymbol:'Simbolo di valuta',
        stripeSecretkey:'Stripe Secret key',
        port:'Porta e-mail',
        hostSmtp:'Host(SMTP)',
        appBar:"Barra dell'app",
        footer:'Piè di pagina',
        body:'Corpo',
        homeHeaderTop:'Intestazione iniziale in alto',
        homeDetailTop:'Dettaglio casa In alto',
        homeHeaderBottom:'Intestazione iniziale in basso',
        homeDetailBottom:'Dettaglio casa in basso',
        homeBox1Header:'Home Box1 Intestazione',
        homeBox1Detail:'Home Box1 Dettaglio',
        homeBox2Header:'Intestazione Home Box2',
        homeBox2Detail:'Home Box2 Dettaglio',
        homeBox3Header:'Intestazione Home Box3',
        homeBox3Detail:'Home Box3 Dettaglio',
        homeBox4Header:'Intestazione Home Box4',
        homeBox4Detail:'Home Box4 Dettaglio',
        feature1Header:'Caratteristica1 Intestazione',
        feature1Detail:'Caratteristica1 Dettaglio',
        feature2Header:'Caratteristica2 Intestazione',
        feature2Detail:'Caratteristica2 Dettaglio',
        feature3Header:'Feature3 Intestazione',
        feature3Detail:'Caratteristica3 Dettaglio',
        feature4Header:'Caratteristica4 Intestazione',
        feature4Detail:'Caratteristica4 Dettaglio',
        registrationText:'Testo di registrazione',
        conactusText:'Contattaci Testo',
        conactusTelephone:'Contattaci Telefono',
        conactusEmail:'Email di contatto',
        landingFooterText:'Testo del piè di pagina di destinazione',
        footerFacebook:'Piè di pagina Facebook',
        footerTwitter:'Twitter a piè di pagina',
        footerLinkedIn:'Piè di pagina LinkedIn',
        footerInstagram:'Piè di pagina Instagram',
        subject:'Argomento',
        header:'Intestazione',
        forgetPasswordEmail:'Password dimenticata E-mail',
        welcomeEmail:'E-mail di benvenuto',
        invitationEmail:'E-mail di invito',
        reportEmail:'E-mail di segnalazione',
        newPassword:'nuova password',
        confirmNewPassword:'Conferma la nuova password',
        change:'Modificare',
        logInTime:'Tempo di accesso',
        logOutTime:'Tempo di disconnessione',
        ip:'IP',
        browser:'Navigatore',
        browserVersion:'Versione browser',
        platform:'piattaforma',
        id:'ID',
        questionCategoryName:'Nome della categoria di domande',
        newCategory:'Nuova categoria',
        editCategory:'Modifica categoria',
        internetDisruption:'Interruzione di Internet!',
        noInternetMsg:"Sembra che la tua connessione Internet non sia stabile o che tu sia offline. Puoi rimanere qui, ti faremo sapere quando tornerai online.",
        entryRestricted:'Ingresso riservato',
        forbiddenMsg:'Non hai accesso qui. Stai provando qualcosa il cui accesso è limitato per te.',
        errorBackMsg:'Prova a tornare alla pagina di accesso facendo clic',
        here:'qui',
        pagenotfound:'pagina non trovata',
        notFoundMsg:'La pagina che stai cercando di raggiungere non è mai esistita in questa realtà o è migrata in un universo parallelo.',
        tokenExpired:'Token scaduto',
        unauthorizedMsg:'Il tuo token attuale è scaduto! devi accedere di nuovo.',
        numberofQuestions:'Numero di domande',
        timeMin:'Tempo (minuto)',    
        marks:'Segni',
        multipleInput:'Ingresso multiplo',
        multipleAnswer:'Risposta multipla',
        multitleAttempt:'Tentativo multiplo',
        scheduleStartDate:'Data di inizio pianificazione',
        scheduleEndDate:'Data di fine pianificazione',
        status:'Stato',
        goLive:'Andare in diretta',
        stopLive:'Interrompi dal vivo',
        newAssessment:'Nuova valutazione',
        editAssessment:'Modifica valutazione',
        markOption:"Contrassegna l'opzione",
        allowMultipleAttempt:'Consenti tentativo illimitato per un singolo utente',
        allowMultipleAnswer:'Rispondi a più risposte per singola domanda per utente',
        allowMultipleInput:'Opzione multipla impostata per singola domanda',
        reqTimeInMin:'Tempo richiesto (in minuti)',
        passMarks:'Punteggio minimo',
        certificateTemplate:'Modello di certificato',
        displayStopOption:"Visualizza l'opzione di arresto",
        displaySkipOption:"Visualizza l'opzione Salta",
        questionSuffle:'Rimescolamento delle domande',
        correctAnswerDisplay:'Visualizzazione della risposta corretta ai candidati',
        participantOption:'Opzione di partecipazione',
        sentInvitationtoEmail:"Invito inviato all'e-mail",
        participantsEmail:'Email dei partecipanti (separati da virgola)',
        sentLive:'Sei sicuro di voler inviare questa valutazione dal vivo?',
        stopFromLive:'Sei sicuro di interrompere questa valutazione dal vivo?',
        addQuestions:'Aggiungi domande',
        addInstructions:'Aggiungi istruzioni',
        newInstruction:'Nuova istruzione',
        editInstruction:'Modifica istruzione',
        instruction:'Istruzione',
        newQuestion:'Nuova domanda',
        editQuestion:'Modifica domanda',
        sampleCSV:'Campione CSV',
        uploadInstructions:'Carica istruzioni',
        questions:'Domande',
        category:'Categoria',
        correctAnswer:'Risposta corretta',
        codeSnippet:'Frammento di codice',
        questionType:'tipo di domanda',
        complexityLavel:'Livello di complessità',
        questionCategory:'Categoria domanda',
        questionYoutubeVideo:'Video della domanda (video incorporato di YouTube)',
        questionImage:'Immagine della domanda',
        answerExplanation:'Risposta Spiegazione',
        selectcategorytofilter:'Seleziona la categoria da filtrare',
        time:'Volta',
        minutes:' minuti',
        view:'Visualizzazione',
        newTemplate:'Nuovo modello',
        editTemplate:'Modifica modello',
        certificateTitle:'Titolo del certificato',
        certificateTopHeader:'Intestazione superiore del certificato',
        certificateMainText:'Testo principale del certificato',
        topLeftLogo:'Logo in alto a sinistra',
        topRightLogo:'Logo in alto a destra',
        bottomLogo:'Logo in basso',
        backGround:'Sfondo',
        signatureLeft:'Firma a sinistra',
        signatureLeftText:'Firma a sinistra del testo',
        signatureRight:'Firma a destra',
        signatureRightText:'Firma a destra del testo',
        selectonetofilter:'Selezionane uno da filtrare',
        sentEmail:'Invia una email',
        sentemailtocheckedstudents:'Invia email agli studenti selezionati',
        search:'Ricerca',
        timeTakenMin:'Tempo impiegato (min)',
        marksObtained:'Marco ottenuto',
        attemptNumber:'Numero di tentativi',
        attemptDateTime:'Tentativo Data-Ora',
        questionMark:'Punto interrogativo',
        answerInput:"Rispondi all'immissione",
        skipped:'Saltato?',
        yourMarks:'I tuoi segni',
        pendingExamine:'In attesa di esame',
        resultSheet:'Foglio dei risultati',
        certificate:'Certificato',
        amountPaidUSD:'Importo pagato (USD)',
        noAssessment:'Nessuna valutazione disponibile!',
        availableAssessment:'Valutazioni disponibili',
        instructions:'Istruzioni',
        continue:'Continua',
        Dashboard:'Pannello',
        Menus:'Menu',
        Roles:'Ruoli',
        Users:'Utenti',
        Category:'Categoria',
        Assessments:'Valutazioni',
        Questions:'Domande',
        Reports:'Rapporti',
        CertificateTemplate:'Modello di certificato',
        ExamineAndReports:'Esame e rapporti',
        Analytics:'Analitica',
        Contacts:'Contatti',
        Payments:'Pagamenti',
        Plans:'Piani',
        AppSettings:"Impostazioni dell'app",
        nameEmptyRuleLanding:'Il nome non può essere vuoto',
        nameCharacterRuleLanding:'La lunghezza deve essere maggiore o uguale a 6 caratteri',
        emailEmptyRuleLanding:"L'e-mail non può essere vuota",
        emailWrongRuleLanding:'Email sbagliata!',
        messageEmptyRuleLanding:'Il messaggio non può essere vuoto',
        messageCharacterRuleLanding:'La lunghezza deve essere maggiore o uguale a 10 caratteri',
        successfullySubmitted:'Inviato con successo',
        planExpiredMsg:'Il tuo piano di pagamento è scaduto! Fai clic su rinnova per ottenerlo di nuovo.',
        billingPlanRenewed:'Piano di fatturazione rinnovato',
        planRenewalSuccess:'Il rinnovo del piano di fatturazione è andato a buon fine! Accedi di nuovo.',
        newContentsAvailable:'Nuovi contenuti disponibili! Fare clic su Aggiorna per applicare le modifiche.',
        mainText:'Testo principale',
        menuDeleteRestriction:'Questo menu è limitato alla cancellazione.',
        successfullyDeleted:'Eliminato con successo',
        successfullySaved:'Salvato con successo',
        duplicateMenuTitle:'Titolo menu duplicato!',
        duplicateURL:'URL duplicato!',
        duplicateOrderNo:"Numero d'ordine duplicato!",
        orderNomustgreaterthan0:"Il numero d'ordine deve essere maggiore di 0!",
        somethingUnexpected:'Qualcosa di inaspettato!',
        successfullyUpdated:'Aggiornato con successo',
        successfullyAssigned:'Assegnato con successo',
        successfullyUnAssigned:"Annullamento dell'assegnazione riuscito",
        limitExpired:'Hai superato il limite del piano di fatturazione. Non è consentito crearne uno nuovo!',
        dateExpired:'Hai superato la data del tuo piano di fatturazione. Non è consentito crearne uno nuovo!',
        duplicateAssessmentTitle:'Titolo di valutazione duplicato!',
        backOnline:'Di nuovo in linea!',
        backOnlineMsg:'La connessione è tornata e puoi ricominciare a navigare.',
        backgroundColor:'Colore di sfondo',
        pleasecheckyourEmail:'Si prega di controllare la tua email',
        emailsentSuccessful:'Email inviata con successo',
        notanimage:"Non un'immagine",
        duplicateFaq:'Domande frequenti duplicate!',
        duplicateInstruction:'Istruzione duplicata!',
        successfullyRenewed:'Rinnovato con successo',
        notallowedtodeletethisondemoversion:'questa operazione non è consentita nella versione demo!',
        thisplanhasexistingusersNotallowedtodeletethis:'Questo piano ha utenti esistenti. Non è consentito eliminare questo.',
        duplicateTitle:'Titolo duplicato!',
        duplicatePrice:'Prezzo duplicato!',
        thereisnouserforthisemail:'Non ci sono utenti per questa email',
        pleasepayfirstthisisaninvalidsession:'Si prega di pagare prima. Questa è una sessione non valida!',
        thisemailalreadyhaveauser:'Questa e-mail non è autorizzata alla registrazione. Si prega di controllare!',
        thisemailnotallowedtoregister:'Questa e-mail non è autorizzata alla registrazione. Si prega di controllare!',
        rolerestricted:"Questo ruolo è limitato all'eliminazione.",
        duplicateRolename:'Nome ruolo duplicato!',
        surveyReset:'Quando si passa a Sondaggio, i valori Mark e Required time vengono reimpostati allo stato iniziale.',
        marksBasedReset:'Quando sei passato alla valutazione basata sui voti, devi impostare la risposta corretta per tutte le domande di questa valutazione.',
        questionWiseReset:"Quando sei passato all'opzione Imposta segno domanda saggia, devi controllare la risposta corretta e contrassegnare tutte le domande di questa valutazione.",
        equalDistributionReset:"Quando sei passato all'opzione per il punteggio di distribuzione equa, devi rivivere questa valutazione.",
        marksChangedReset:'Poiché hai cambiato i voti, devi rivivere questa valutazione.',
        liveInterruptionForNoQuestion:'Non è possibile vivere questa valutazione. Per favore, aggiungi alcune domande!',
        liveInterruptionForNoCorrectAnswer:'Non è possibile vivere questa valutazione. Le domande non hanno una risposta corretta. Per favore impostale prima!',
        liveInterruptionForNoMarksSet:'Non è possibile vivere questa valutazione. I punti delle domande non sono ancora stati impostati. Per favore, impostali prima!',
        thissssessmentislivenow:'Questa valutazione è in diretta ora!',
        thisAssessmentisstoppedfromlive:'Questa valutazione è interrotta dal vivo!',
        deleteAssessmentReset:"Poiché hai eliminato una domanda da una valutazione in tempo reale, devi ricominciare questa valutazione dall'elenco delle valutazioni.",
        maxQuestionQuota:'Hai già raggiunto la quota massima del limite di domande! Non è consentito crearne uno nuovo!',
        duplicateQuestion:'Domanda duplicata!',
        newQuestionAddAssessmentStart:"Poiché hai aggiunto una nuova domanda a una valutazione in tempo reale, devi ricominciare questa valutazione dall'elenco delle valutazioni",
        questionMarksChangedAssessmentStart:"Questo elemento viene interrotto dal vivo poiché hai modificato i segni di una valutazione in esecuzione. devi ricominciare questa valutazione dall'elenco delle valutazioni",
        bulkQuestionAdd:"Poiché hai aggiunto domande collettive, devi ricominciare questa valutazione dall'elenco delle valutazioni se era in diretta",
        categoryUsageOthers:'Questa categoria sta usando con la domanda. Non è possibile eliminare.',
        categoryExists:'Questa categoria esiste già!',
        pdf:'PDF',
        excel:'EXCELECCELLERE',
        csv:'CSV',
        typePassword:'Inserisci password',
        wrongPassword:'Password errata!',
        passwordNotMatched:'Nuova e Conferma password non abbinate!',
        passwordChangedNotAllowedDemo:'Modifica della password non consentita per questo utente nella versione demo.',
        onlinePaymentnotallowed:'Pagamento online non consentito da qui!',
        pleaseCheckyouremail:'Si prega di controllare la tua email',
        incorrectEmailPassword:'Email/password errata!',
        errorNetworkConnection:'Errore stabilito nella connessione di rete!',
        thisFieldMaynotbeEmpty:'Questo campo non può essere vuoto',
        theValueMustbeanIntegerNumber:'Il valore deve essere un numero intero',
        theValueMustBeGreaterThanZero:'Il valore deve essere maggiore di zero',
        emailSettingsNotDone:'Impostazioni e-mail non ancora completate! Chiedi al tuo amministratore di farlo.',
        noCheckedStudent:"Nessun candidato verificato trovato! Prima controlla i candidati dall'elenco, quindi invia loro un'e-mail",
        emailSentSuccessfully:'email inviata correttamente',
        markCanNotBeLessZero:'I voti ottenuti non possono essere vuoti e inferiori a 0!',
        marksObtaincannotgreaterthanQuestionMarks:'Mark Ottenere non può superare il punto interrogativo!',
        containsDescriptiveQuestions:"Questa valutazione contiene domande descrittive. Troverai questo risultato su Rapporti dopo la revisione da parte dell'amministratore.",
        student:'Alunno',
        yourAnswer:'La tua risposta',
        questionMarks:'Punto interrogativo',
        thisAssessmentWilllive:'Questa valutazione vivrà in base al programma.',
        paymentSettingsNotDone:'Le tue impostazioni di pagamento non sono ancora state completate! Si prega di impostare quelli quindi imporre il prezzo qui.',
        noScheduleStartDate:'Nessuna data di inizio programma trovata! per favore mettine uno.',
        scheduleStartDateShouldGreater:'La data di fine del programma deve essere maggiore o uguale alla data di inizio del programma!',
        noCommaSeperatedEmail:"Nessuna email separata da virgola trovata! Aggiungi alcune e-mail separate da virgole all'e-mail dei partecipanti",
        passMarksshouldnotgreaterthanAssessmentMarks:'Il punteggio minimo non deve superare il punteggio di valutazione!',
        writeYourAnswerHere:'Scrivi qui la tua risposta',   
        submitAnswer:'Inviare risposta',     
        emptyAnswer:'Risposta vuota!',
        multipleAnswernotAllowed:'Risposta multipla non consentita!',
        csvUploadInstruction:'Non caricato! Controlla attentamente il tuo CSV e segui anche le istruzioni per il caricamento.',
        csvUploadError:'Questo non è un file CSV! È consentito solo il caricamento di file CSV',
        emptyCorrectAnswer:'Risposta corretta vuota!controlla.',
        onlyYouTubeVideo:'È consentito solo il video di incorporamento di YouTube! Si prega di controllare il video della domanda.',
        billingPlanRenewalSuccess:'Il rinnovo del piano di fatturazione è andato a buon fine! Accedi di nuovo.',
        csvUpload:'Caricamento CSV',
        skip:'Saltare',
        start:'Inizio',
        refresh:'ricaricare',
        pay:'Paga'      

    },
    'in': {   
        home:'घर',
        pricing:'मूल्य निर्धारण',
        features:'विशेषताएं',
        contact:'संपर्क करना',
        signIn:'साइन इन करें',
        getRegister:'पंजीकृत हो जाओ',
        registerNow:'अभी पंजीकरण करें',
        contactUs:'संपर्क करें',
        admin:'व्यवस्थापक',
        candidate:'उम्मीदवार',
        assess:'आकलन',
        liveAssess:'लाइव आकलन',
        question:'प्रशन',
        dashboardChart1:'भुगतान (तिथिवार)',
        dashboardChart2:'भागीदारी (तारीख वार)',
        dashboardChart3:'भागीदारी (विषयवार)',
        dashboardChart4:'लॉग इन करें (दिनांक वार)',
        language:'भाषा',
        personalize:'वैयक्तिकृत करें',
        leaveHeader:'छोड़ना चाहते हैं?',
        leaveConfirm:'जाने के लिए साइनआउट दबाएं',
        stay:'यहीं रुकिए',
        signOut:'प्रस्थान करें',
        Profile:'प्रोफ़ाइल',
        Password:'कुंजिका',
        Browsing:'ब्राउजिंग',
        FAQ:'सामान्य प्रश्न',
        email:'ईमेल',
        telephone:'टेलीफ़ोन',
        message:'संदेश',
        submit:'प्रस्तुत',
        password:'कुंजिका',
        name:'नाम',
        forgetPassword:'पासवर्ड भूल गए',
        register:'पंजीकरण करवाना',
        close:'बंद करना',
        sentPassword:'भेजा गया पासवर्ड',
        candidateRegister:'उम्मीदवार पंजीकरण',
        adminRegister:'व्यवस्थापक पंजीकरण',
        passwordLengthMessage:'कम से कम 8 अक्षर',
        required:'आवश्यक',
        min8Character:'न्यूनतम 8 वर्ण',
        emailInvalid:'ईमेल मान्य होना चाहिए',
        assessments:'आकलन',
        questionsOnEachAssessment:'प्रत्येक मूल्यांकन पर प्रश्न',
        responsesForEachAssessment:'प्रत्येक मूल्यांकन के लिए प्रतिक्रियाएँ',
        subscribe:'सदस्यता लेने के',
        newMenu:'नया मेनू',
        editMenu:'संपादन मेनू',
        menuTitle:'मेनू शीर्षक',
        url:'यूआरएल',
        orderNo:'आदेश संख्या.',
        iconMaterial:'चिह्न (सामग्री-चिह्न)',
        actions:'कार्रवाई',
        save:'सहेजें',
        cancel:'रद्द करना',
        ok:'ठीक है',
        deleteConfirmMessage:'क्या आप सुनिश्चित रूप से इस आइटम को मिटाना चाहते हैं?',
        mobile:'मोबाइल',
        dateOfBirth:'जन्म की तारीख',
        displayName:'प्रदर्शित होने वाला नाम',
        role:'भूमिका',
        plan:'योजना',
        interval:'मध्यान्तर',
        paymentMode:'भुगतान का प्रकार',
        expireyDate:'समाप्ति तिथि',
        transactionDetail:'लेन-देन का विवरण',
        newUser:'नया उपयोगकर्ता',
        editUser:'यूजर को संपादित करो',
        billingPlan:'बिलिंग योजना',
        profilePicture:'प्रोफ़ाइल फोटो',
        renewPlan:'नवीनीकरण योजना',
        previousPlan:'पिछली योजना',
        previousPaymentMode:'पिछला भुगतान मोड',
        previousTransactionDetail:'पिछला लेन-देन विवरण',
        renew:'नवीकरण',
        newPlan:'नई योजना',
        newMode:'नया मोड',
        delete:'मिटाना',
        description:'विवरण',
        newRole:'नयी भूमिका',
        editRole:'भूमिका संपादित करें',
        assignedMenus:'असाइन किया गया मेनू',
        edit:'संपादन करना',
        newFaq:'न्यू फैक',
        editFaq:'एफएक्यू संपादित करें',
        title:'शीर्षक',
        dateTime:'दिनांक समय',
        assessment:'मूल्यांकन',
        priceUsd:'मूल्य (यूएसडी)',
        questionPerAssessmentCount:'प्रश्न (प्रति मूल्यांकन)',
        responsePerAssessmentCount:'प्रतिक्रिया (प्रति आकलन)',
        startDate:'दिनांक प्रारंभ',
        endDate:'तिथि समाप्ति',
        stripeSessionId:'धारी सत्र आईडी',
        userEmail:'उपयोगकर्ता ईमेल',
        transactionEmail:'लेन-देन ईमेल',
        editPlan:'योजना संपादित करें',
        additionalText:'अतिरिक्त पाठ',
        Monthly:'महीने के',
        yearly:'सालाना',
        generalSettings:'सामान्य सेटिंग्स',
        examSettings:'परीक्षा सेटिंग्स',
        paymentSettings:'भुगतान सेटिंग्स',
        emailSettings:'ईमेल सेटिंग',
        colorSettings:'रंग सेटिंग्स',
        landingPage:'लैंडिंग पेज',
        emailText:'ईमेल पाठ',
        siteTitle:'घटनास्थल शीर्षक',
        welcomeMessage:'स्वागत संदेश',
        copyrightText:'कॉपीराइट पाठ',
        allowWelcomeEmail:'स्वागत ईमेल की अनुमति दें',
        allowFAQ:'एफएक्यू की अनुमति दें',
        siteLogo:'साइट लोगो',
        appVersion:'एप्लिकेशन वेरीज़न',
        siteFavicon:'साइट फ़ेविकॉन',
        browserrefreshendtheexam:'ब्राउज़र रीफ्रेश परीक्षा समाप्त करें',
        sitelogoonexampage:'परीक्षा पृष्ठ पर साइट का लोगो',
        stripe:'Stripe',
        currency:'मुद्रा',
        currencySymbol:'मुद्रा चिन्ह',
        stripeSecretkey:'Stripe Secret key',
        port:'ईमेल पोर्ट',
        hostSmtp:'होस्ट (एसएमटीपी)',
        appBar:'ऐप बार',
        footer:'फ़ुटबाल',
        body:'शरीर',
        homeHeaderTop:'होम हैडर टॉप',
        homeDetailTop:'होम डिटेल टॉप',
        homeHeaderBottom:'होम हेडर बॉटम',
        homeDetailBottom:'होम डिटेल बॉटम',
        homeBox1Header:'होम बॉक्स1 हैडर',
        homeBox1Detail:'होम बॉक्स1 विवरण',
        homeBox2Header:'होम बॉक्स2 हैडर',
        homeBox2Detail:'होम बॉक्स2 विवरण',
        homeBox3Header:'होम बॉक्स3 हैडर',
        homeBox3Detail:'होम बॉक्स3 विवरण',
        homeBox4Header:'होम बॉक्स4 हैडर',
        homeBox4Detail:'होम बॉक्स4 विवरण',
        feature1Header:'फ़ीचर 1 हैडर',
        feature1Detail:'फ़ीचर 1 विवरण',
        feature2Header:'फ़ीचर 2 हैडर',
        feature2Detail:'फ़ीचर 2 विवरण',
        feature3Header:'फ़ीचर 3 हैडर',
        feature3Detail:'फ़ीचर 3 विवरण',
        feature4Header:'फ़ीचर 4 हैडर',
        feature4Detail:'फ़ीचर 4 विवरण',
        registrationText:'पंजीकरण पाठ',
        conactusText:'हमसे संपर्क करें पाठ',
        conactusTelephone:'हमसे संपर्क करें टेलीफोन',
        conactusEmail:'संपर्क-हमें ईमेल',
        landingFooterText:'लैंडिंग पाद पाठ',
        footerFacebook:'पाद फेसबुक',
        footerTwitter:'पाद चहचहाना',
        footerLinkedIn:'फुटर लिंक्डइन',
        footerInstagram:'फुटर इंस्टाग्राम',
        subject:'विषय',
        header:'हैडर',
        forgetPasswordEmail:'पासवर्ड ईमेल भूल जाओ',
        welcomeEmail:'स्वागत ईमेल',
        invitationEmail:'आमंत्रण ईमेल',
        reportEmail:'ईमेल की रिपोर्ट करें',
        newPassword:'नया पासवर्ड',
        confirmNewPassword:'नए पासवर्ड की पुष्टि करें',
        change:'परिवर्तन',
        logInTime:'लॉग इन समय',
        logOutTime:'लॉगआउट समय',
        ip:'आईपी',
        browser:'ब्राउज़र',
        browserVersion:'ब्राउज़र संस्करण',
        platform:'प्लैटफ़ॉर्म',
        id:'पहचान',
        questionCategoryName:'प्रश्न श्रेणी का नाम',
        newCategory:'नई श्रेणी',
        editCategory:'श्रेणी संपादित करें',
        internetDisruption:'इंटरनेट व्यवधान!',
        noInternetMsg:"ऐसा लगता है कि आपकी इंटरनेट कनेक्टिविटी स्थिर नहीं है या आप ऑफ़लाइन हैं। आप यहां रह सकते हैं, जब आप वापस ऑनलाइन आएंगे तो हम आपको बताएंगे.",
        entryRestricted:'प्रवेश प्रतिबंधित',
        forbiddenMsg:'यहां आपकी पहुंच नहीं है। आप कुछ ऐसा करने की कोशिश कर रहे हैं जिसकी पहुंच आपके लिए सीमित है.',
        errorBackMsg:'क्लिक करके साइन इन पृष्ठ पर वापस जाने का प्रयास करें',
        here:'यहाँ',
        pagenotfound:'पृष्ठ नहीं मिला',
        notFoundMsg:'आप जिस पृष्ठ को प्राप्त करने का प्रयास कर रहे हैं वह इस वास्तविकता में कभी अस्तित्व में नहीं था, या समानांतर ब्रह्मांड में स्थानांतरित हो गया है.',
        tokenExpired:'टोकन समाप्त हो गया',
        unauthorizedMsg:'आपका वर्तमान टोकन समाप्त हो गया है! आपको फिर से साइन इन करने की आवश्यकता है।',
        numberofQuestions:'प्रश्नों की संख्या',
        timeMin:'समय (मिनट)',    
        marks:'निशान',
        multipleInput:'एकाधिक इनपुट',
        multipleAnswer:'एकाधिक उत्तर',
        multitleAttempt:'बहुप्रयास',
        scheduleStartDate:'अनुसूची प्रारंभ तिथि',
        scheduleEndDate:'अनुसूची समाप्ति तिथि',
        status:'दर्जा',
        goLive:'प्रत्यक्ष जाना',
        stopLive:'लाइव बंद करो',
        newAssessment:'नया आकलन',
        editAssessment:'मूल्यांकन संपादित करें',
        markOption:'मार्क विकल्प',
        allowMultipleAttempt:'एकल उपयोगकर्ता के लिए असीमित प्रयास की अनुमति दें',
        allowMultipleAnswer:'उपयोगकर्ता द्वारा एक प्रश्न के लिए एकाधिक उत्तर लें',
        allowMultipleInput:'एकल प्रश्न के लिए एकाधिक विकल्प सेट',
        reqTimeInMin:'आवश्यक समय (मिनटों में)',
        passMarks:'उत्तीर्ण अंक',
        certificateTemplate:'प्रमाणपत्र टेम्पलेट',
        displayStopOption:'स्टॉप विकल्प प्रदर्शित करें',
        displaySkipOption:'छोड़ें विकल्प प्रदर्शित करें',
        questionSuffle:'प्रश्न फेरबदल',
        correctAnswerDisplay:'उम्मीदवारों को सही उत्तर प्रदर्शन',
        participantOption:'भागीदारी विकल्प',
        sentInvitationtoEmail:'ईमेल पर आमंत्रण भेजा गया',
        participantsEmail:'प्रतिभागियों का ईमेल (अल्पविराम से अलग)',
        sentLive:'क्या आप वाकई इस आकलन को लाइव भेजना चाहते हैं?',
        stopFromLive:'क्या आप वाकई इस मूल्यांकन को लाइव से रोकना चाहते हैं?',
        addQuestions:'प्रश्न जोड़ें',
        addInstructions:'निर्देश जोड़ें',
        newInstruction:'नया निर्देश',
        editInstruction:'निर्देश संपादित करें',
        instruction:'अनुदेश',
        newQuestion:'नया प्रश्न',
        editQuestion:'प्रश्न संपादित करें',
        sampleCSV:'नमूना सीएसवी',
        uploadInstructions:'निर्देश अपलोड करें',
        questions:'प्रशन',
        category:'वर्ग',
        correctAnswer:'सही उत्तर',
        codeSnippet:'सांकेतिक टुकड़ा',
        questionType:'प्रश्न प्रकार',
        complexityLavel:'जटिलता स्तर',
        questionCategory:'प्रश्न श्रेणी',
        questionYoutubeVideo:'प्रश्न वीडियो (यूट्यूब एम्बेड वीडियो)',
        questionImage:'प्रश्न छवि',
        answerExplanation:'उत्तर स्पष्टीकरण',
        selectcategorytofilter:'फ़िल्टर करने के लिए श्रेणी का चयन करें',
        time:'समय',
        minutes:' मिनट',
        view:'देखना',
        newTemplate:'नया टेम्पलेट',
        editTemplate:'टेम्प्लेट संपादित करें',
        certificateTitle:'प्रमाणपत्र शीर्षक',
        certificateTopHeader:'सर्टिफिकेट टॉप हैडर',
        certificateMainText:'प्रमाणपत्र मुख्य पाठ',
        topLeftLogo:'शीर्ष बायां लोगो',
        topRightLogo:'शीर्ष दायां लोगो',
        bottomLogo:'निचला लोगो',
        backGround:'पार्श्वभूमि',
        signatureLeft:'हस्ताक्षर बायां',
        signatureLeftText:'हस्ताक्षर वाम पाठ',
        signatureRight:'हस्ताक्षर अधिकार',
        signatureRightText:'हस्ताक्षर सही पाठ',
        selectonetofilter:'फ़िल्टर करने के लिए एक का चयन करें',
        sentEmail:'ईमेल भेजें',
        sentemailtocheckedstudents:'चेक किए गए छात्रों को ईमेल भेजें',
        search:'खोज',
        timeTakenMin:'लिया गया समय (मिनट)',
        marksObtained:'मार्क प्राप्त किया',
        attemptNumber:'प्रयास संख्या',
        attemptDateTime:'दिनांक-समय का प्रयास करें',
        questionMark:'प्रश्न चिन्ह',
        answerInput:'उत्तर इनपुट',
        skipped:'छोड़ दिया?',
        yourMarks:'आपका निशान',
        pendingExamine:'लंबित जांच',
        resultSheet:'रिजल्ट शीट',
        certificate:'प्रमाणपत्र',
        amountPaidUSD:'भुगतान की गई राशि (यूएसडी)',
        noAssessment:'कोई आकलन उपलब्ध नहीं है!',
        availableAssessment:'उपलब्ध आकलन',
        instructions:'निर्देश',
        continue:'जारी रखना',
        Dashboard:'डैशबोर्ड',
        Menus:'मेनू',
        Roles:'भूमिकाएँ',
        Users:'उपयोगकर्ताओं',
        Category:'वर्ग',
        Assessments:'आकलन',
        Questions:'प्रशन',
        Reports:'रिपोर्टों',
        CertificateTemplate:'प्रमाणपत्र टेम्पलेट',
        ExamineAndReports:'परीक्षा और रिपोर्ट',
        Analytics:'एनालिटिक्स',
        Contacts:'संपर्क',
        Payments:'भुगतान',
        Plans:'योजनाओं',
        AppSettings:'एप्लिकेशन सेटिंग',
        nameEmptyRuleLanding:'नाम खाली नहीं हो सकता',
        nameCharacterRuleLanding:'लंबाई 6 कैरैक्टर से अधिक या उसके बराबर होनी चाहिए',
        emailEmptyRuleLanding:'ईमेल खाली नहीं हो सकता',
        emailWrongRuleLanding:'गलत ईमेल!',
        messageEmptyRuleLanding:'संदेश खाली नहीं हो सकता',
        messageCharacterRuleLanding:'लंबाई 10 कैरैक्टर से अधिक या उसके बराबर होनी चाहिए',
        successfullySubmitted:'सफलतापूर्वक प्रस्तुत किया गया',
        planExpiredMsg:'आपकी भुगतान योजना समाप्त हो गई! इसे फिर से प्राप्त करने के लिए नवीनीकरण पर क्लिक करें.',
        billingPlanRenewed:'बिलिंग योजना का नवीनीकरण किया गया',
        planRenewalSuccess:'बिलिंग योजना का नवीनीकरण सफल रहा! फिर से साइन इन करें.',
        newContentsAvailable:'नई सामग्री उपलब्ध है! परिवर्तनों को प्रभावित करने के लिए ताज़ा करें पर क्लिक करें.',
        mainText:'मुख्य पाठ',
        menuDeleteRestriction:'यह मेनू हटाने के लिए प्रतिबंधित है.',
        successfullyDeleted:'सफलतापूर्वक हटा दिया गया',
        successfullySaved:'सफलतापूर्वक संचित कर लिया गया है',
        duplicateMenuTitle:'डुप्लिकेट मेनू शीर्षक!',
        duplicateURL:'डुप्लीकेट यूआरएल!',
        duplicateOrderNo:'डुप्लीकेट ऑर्डर नंबर!',
        orderNomustgreaterthan0:'आदेश संख्या 0 से अधिक होनी चाहिए!',
        somethingUnexpected:'कुछ अनपेक्षित!',
        successfullyUpdated:'सफलतापूर्वक उत्परिवर्तित',
        successfullyAssigned:'सफलतापूर्वक सौंपा गया',
        successfullyUnAssigned:'सफलतापूर्वक अन-असाइन किया गया',
        limitExpired:'आप अपनी बिलिंग योजना की सीमा पार कर चुके हैं। नया बनाने की अनुमति नहीं है!',
        dateExpired:'आप अपनी बिलिंग योजना तिथि पार कर चुके हैं। नया बनाने की अनुमति नहीं है!',
        duplicateAssessmentTitle:'डुप्लिकेट मूल्यांकन शीर्षक!',
        backOnline:'वापस ऑनलाइन!',
        backOnlineMsg:'कनेक्शन वापस आ गया है और आप फिर से ब्राउज़ करना शुरू कर सकते हैं।',
        backgroundColor:'पीछे का रंग',
        pleasecheckyourEmail:'कृपया अपनी ईमेल देखें',
        emailsentSuccessful:'ईमेल भेजा गया सफल',
        notanimage:'छवि नहीं',
        duplicateFaq:'डुप्लिकेट एफएक्यू!',
        duplicateInstruction:'डुप्लिकेट निर्देश!',
        successfullyRenewed:'सफलतापूर्वक नवीनीकरण किया गया',
        notallowedtodeletethisondemoversion:'डेमो संस्करण पर इस ऑपरेशन की अनुमति नहीं है!',
        thisplanhasexistingusersNotallowedtodeletethis:'इस योजना में मौजूदा उपयोगकर्ता हैं। इसे हटाने की अनुमति नहीं है.',
        duplicateTitle:'डुप्लीकेट शीर्षक!',
        duplicatePrice:'डुप्लीकेट कीमत!',
        thereisnouserforthisemail:'इस ईमेल के लिए कोई उपयोक्ता नहीं है',
        pleasepayfirstthisisaninvalidsession:'कृपया पहले भुगतान करें। यह एक अमान्य सत्र है!',
        thisemailalreadyhaveauser:'इस ईमेल में पहले से ही एक उपयोगकर्ता है!',
        thisemailnotallowedtoregister:'इस ईमेल को पंजीकृत करने की अनुमति नहीं है। कृपया जांचें!',
        rolerestricted:'यह भूमिका हटाने के लिए प्रतिबंधित है.',
        duplicateRolename:'डुप्लीकेट रोल नाम!',
        surveyReset:'जैसे ही आपने सर्वेक्षण पर स्विच किया, मार्क और आवश्यक समय मान प्रारंभिक अवस्था में रीसेट हो गए.',
        marksBasedReset:'जैसे ही आप अंक आधारित मूल्यांकन पर स्विच करते हैं, आपको इस मूल्यांकन के सभी प्रश्नों के लिए सही उत्तर निर्धारित करने की आवश्यकता होती है.',
        questionWiseReset:'जैसे ही आप प्रश्नवार सेट मार्क विकल्प पर स्विच करते हैं, आपको इस आकलन के सभी प्रश्नों के लिए सही उत्तर और चिह्न की जांच करने की आवश्यकता होती है.',
        equalDistributionReset:'जैसे ही आपने समान वितरण मार्क विकल्प पर स्विच किया, आपको इस आकलन को फिर से जीना होगा।',
        marksChangedReset:'जैसे तुमने निशान बदले, तुम्हें इस आकलन को फिर से जीना है।',
        liveInterruptionForNoQuestion:'इस आकलन को जीना संभव नहीं है। कृपया कुछ प्रश्न जोड़ें!',
        liveInterruptionForNoCorrectAnswer:'इस आकलन को जीना संभव नहीं है। प्रश्नों का कोई सही उत्तर नहीं है। कृपया उन्हें पहले सेट करें!',
        liveInterruptionForNoMarksSet:'इस मूल्यांकन को जीना संभव नहीं है। प्रश्न चिह्न अभी सेट नहीं किए गए हैं। कृपया उन्हें पहले सेट करें!',
        thissssessmentislivenow:'यह आकलन अभी लाइव है!',
        thisAssessmentisstoppedfromlive:'यह आकलन लाइव से रोक दिया गया है!',
        deleteAssessmentReset:'जैसा कि आपने एक लाइव मूल्यांकन से एक प्रश्न हटा दिया है, आपको मूल्यांकन सूची से इस आकलन को फिर से शुरू करना होगा.',
        maxQuestionQuota:'आप पहले ही प्रश्न सीमा के अधिकतम कोटा तक पहुँच चुके हैं! नया बनाने की अनुमति नहीं है!',
        duplicateQuestion:'डुप्लीकेट प्रश्न!',
        newQuestionAddAssessmentStart:'जैसे ही आपने एक लाइव असेसमेंट में एक नया प्रश्न जोड़ा, आपको असेसमेंट सूची से इस असेसमेंट को फिर से शुरू करना होगा',
        questionMarksChangedAssessmentStart:'यह आइटम लाइव से रोक दिया गया है क्योंकि आपने चल रहे आकलन के अंक बदल दिए हैं। आपको यह आकलन फिर से आकलन सूची से शुरू करना होगा',
        bulkQuestionAdd:'जैसा कि आपने बल्क प्रश्नों को जोड़ा है, यदि यह मूल्यांकन लाइव था, तो आपको इस मूल्यांकन को फिर से मूल्यांकन सूची से शुरू करना होगा',
        categoryUsageOthers:'यह श्रेणी प्रश्न के साथ प्रयोग कर रही है। हटाना संभव नहीं है.',
        categoryExists:'यह श्रेणी पहले से मौजूद है!',
        pdf:'पीडीएफ',
        excel:'एक्सेल',
        csv:'सीएसवी',
        typePassword:'पासवर्ड लिखें',
        wrongPassword:'गलत पासवर्ड!',
        passwordNotMatched:'नया और पुष्टि पासवर्ड मेल नहीं खाता!',
        passwordChangedNotAllowedDemo:'डेमो संस्करण पर इस उपयोगकर्ता के लिए पासवर्ड बदलने की अनुमति नहीं है.',
        onlinePaymentnotallowed:'यहां से ऑनलाइन भुगतान की अनुमति नहीं है!',
        pleaseCheckyouremail:'कृपया अपनी ईमेल देखें',
        incorrectEmailPassword:'गलत ईमेल/पासवर्ड!',
        errorNetworkConnection:'नेटवर्क कनेक्शन में स्थापित त्रुटि!',
        thisFieldMaynotbeEmpty:'यह फ़ील्ड खाली नहीं हो सकता',
        theValueMustbeanIntegerNumber:'मान एक पूर्णांक संख्या होना चाहिए',
        theValueMustBeGreaterThanZero:'मान शून्य से अधिक होना चाहिए',
        emailSettingsNotDone:'ईमेल सेटिंग अभी तक नहीं की गई है! कृपया अपने एडमिन से ऐसा करने के लिए कहें.',
        noCheckedStudent:'कोई चेक किए गए उम्मीदवार नहीं मिले! पहले उम्मीदवारों को सूची से जांचें फिर उन्हें ईमेल भेजा',
        emailSentSuccessfully:'सफलतापूर्वक भेजा गया ईमेल',
        markCanNotBeLessZero:'प्राप्त अंक खाली और 0 से कम नहीं हो सकते!',
        marksObtaincannotgreaterthanQuestionMarks:'मार्क प्राप्त करना प्रश्न चिह्न से अधिक नहीं हो सकता!',
        containsDescriptiveQuestions:'इस आकलन में वर्णनात्मक प्रश्न शामिल हैं। एडमिन द्वारा समीक्षा करने के बाद आपको यह परिणाम रिपोर्ट्स पर मिलेगा।',
        student:'छात्र',
        yourAnswer:'आपका उत्तर',
        questionMarks:'प्रश्न चिह्न',
        thisAssessmentWilllive:'यह मूल्यांकन शेड्यूल के आधार पर लाइव होगा।',
        paymentSettingsNotDone:'आपकी भुगतान सेटिंग अभी पूरी नहीं हुई है! कृपया उन्हें सेट करें और फिर यहां कीमत लगाएं।',
        noScheduleStartDate:'कोई शेड्यूल प्रारंभ दिनांक नहीं मिला! कृपया एक लगाएं।',
        scheduleStartDateShouldGreater:'शेड्यूल की समाप्ति तिथि, शेड्यूल की आरंभ तिथि से अधिक या उसके बराबर होनी चाहिए!',
        noCommaSeperatedEmail:'अल्पविराम से अलग किया गया कोई ईमेल नहीं मिला! प्रतिभागियों के ईमेल में अल्पविराम से अलग किए गए कुछ ईमेल जोड़ें',
        passMarksshouldnotgreaterthanAssessmentMarks:'पास मार्क असेसमेंट मार्क से बड़ा नहीं होना चाहिए!',
        writeYourAnswerHere:'अपना उत्तर यहाँ लिखें',   
        submitAnswer:'उत्तर सबमिट करें',     
        emptyAnswer:'खाली उत्तर!',
        multipleAnswernotAllowed:'एकाधिक उत्तर की अनुमति नहीं है!',
        csvUploadInstruction:'अपलोड नहीं किया गया! कृपया अपने सीएसवी की सावधानीपूर्वक जांच करें अपलोड निर्देशों का भी पालन करें।',
        csvUploadError:'यह एक CSV फ़ाइल नहीं है! केवल CSV फ़ाइल अपलोड की अनुमति है',
        emptyCorrectAnswer:'सही उत्तर खाली है! कृपया जांचें।',
        onlyYouTubeVideo:'केवल यूट्यूब एम्बेड वीडियो की अनुमति है! कृपया प्रश्न वीडियो देखें।',
        billingPlanRenewalSuccess:'बिलिंग योजना का नवीनीकरण सफल रहा! फिर से साइन इन करें।',
        csvUpload:'सीएसवी अपलोड',
        skip:'छोडना',
        start:'शुरू',
        refresh:'ताज़ा करना',
        pay:'भुगतान'      

    },
};
const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'bn', // set fallback locale
    messages, // set locale messages
});
export default i18n;